var render = function render(){var _vm=this,_c=_vm._self._c;return _c('autocomplete',{ref:"autocomplete",attrs:{"search":_vm.search,"default-value":_vm.value,"get-result-value":_vm.getResultValue,"debounce-time":_vm.debounceTime},on:{"submit":function($event){return _vm.$emit('submit', $event)}},scopedSlots:_vm._u([{key:"default",fn:function({
      rootProps,
      inputProps,
      inputListeners,
      resultListProps,
      resultListListeners,
      results,
      resultProps,
    }){return [_c('div',_vm._b({},'div',rootProps,false),[_c('ui-text-field',_vm._b({ref:"input",attrs:{"label":_vm.label,"darken":_vm.darken,"error-messages":_vm.errorMessages},on:{"blur":inputListeners.blur,"focus":inputListeners.focus,"keydown":inputListeners.keydown,"input":function($event){return _vm.onInput($event, inputListeners)}}},'ui-text-field',inputProps,false)),_c('ul',_vm._g(_vm._b({},'ul',resultListProps,false),resultListListeners),_vm._l((results),function(result,index){return _c('li',_vm._b({key:index},'li',resultProps[index],false),[_vm._t("option",function(){return [_vm._v(" "+_vm._s(result?.[_vm.returnProp])+" ")]},{"option":result})],2)}),0)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }