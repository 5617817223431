import DashboardOperatorRepository from '@/repository/admin/dashboardOperatorRepository'
import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { UserRepository } from '@/repository/admin'

const state = {
  filters: {
    dailyEarning: StorageService.get(storageKeys.DASHBOARD_OPERATOR_DAILY_EARNING) || {},
  },
}

const getters = {
  filters: state => state.filters,
}

const actions = {
  async loadOrderAndApproveOperator(context, date) {
    const { data } = await DashboardOperatorRepository.orderAndApprove(date)
    return data
  },
  async loadRatingOperator(context, filter) {
    const { data } = await DashboardOperatorRepository.operatorRating(filter)
    return data
  },
  async loadOrdersQueue() {
    const { data } = await UserRepository.ordersQueue()
    return data
  },
  async loadDailyEarning(context, date) {
    context.commit('setDailyEarningFilters', date)
    StorageService.set(storageKeys.DASHBOARD_OPERATOR_DAILY_EARNING, date)
    const { data } = await DashboardOperatorRepository.dailyEarning(date)
    return data
  },
}
const mutations = {
  setDailyEarningFilters(state, filters) {
    state.filters.dailyEarning = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
