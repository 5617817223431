import SuperRepository from '../superRepository'

export default new (class OrderRepository extends SuperRepository {
  baseUri() {
    return 'order'
  }
  list = async (params, config = {}) => {
    return this.httpClient().get(`${this.baseUri()}`, params, config)
  }
  generateOrderInvoice = async ({ id }, params ) =>
    this.httpClient().getFile(`invoice/order/${id}/generate`, params)
  export = async (params = {}) => this.httpClient().getFile(`${this.baseUri()}/orders/export`, params)

  checkOtherOperators = async id =>
    this.httpClient().get(`${this.baseUri()}/${id}/check_user`)
  getCallRecords = async id =>
    this.httpClient().get(`${this.baseUri()}/${id}/record_file`)

  bulkDeleteOrders = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_delete`, data, params)

  logisticSynchronization = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/logistic_synchronization`, data, params)
  // ======================================================================================
  fraud = async params => this.httpClient().get(`${this.baseUri()}/fraud_statistic`, params)
  loadAdditionalPhone = async id =>
    this.httpClient().get(`${this.baseUri()}/${id}/additional_phone`)
  loadOrderHistory = async ({ id, params }) =>
    this.httpClient().get(`${this.baseUri()}/${id}/order_history`, params)
  loadOrderQuestionnaire = async id =>
    this.httpClient().get(`${this.baseUri()}/${id}/questionnaire`)
  createCall = async id =>
    this.httpClient().post(`${this.baseUri()}/${id}/create_call`)
  changePhone = async ({ id, ...data }) =>
    this.httpClient().post(`${this.baseUri()}/${id}/change_phone`, data)
  report = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/order_report`, data, params)
  webList = async params => this.httpClient().get(`${this.baseUri()}/orders_web_list`, params)
  bulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_update`, data, params)
  bulkInvoiceExport = async ({ zip, ...data }, params) =>
    this.httpClient().post(`${this.baseUri()}/order_invoice_bulk${!zip ? '?pdf_file=1' : ''}`, data, params)
  bulkLabelsExport = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/order_label_bulk`, data, params)
  suspiciousBulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/suspicious_bulk_update`, data, params)
  analytica = async params => this.httpClient().get(`${this.baseUri()}/web_analytica`, params)
  analyticaExport = async params => this.httpClient().getFile(`${this.baseUri()}/web_analytica_export`, params)
  createPackerTask = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/packer_task`, data, params)
  exportDistrict = async (params, config = {}) => {
    return this.httpClient().get(`${this.baseUri()}/order_export_district`, params, config)
  }
  distributionCheck = async params =>
    this.httpClient().post(`${this.baseUri()}/distribution_of_check_report`, params)
  ordersWithoutBarcodeReport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/order_with_out_barcode`, data, params)
  startDispute = async id => this.httpClient().post(`${this.baseUri()}/${id}/dispute-start`)
  loadPhoneInfo = async ({ id, params }) =>
    this.httpClient().get(`${this.baseUri()}/${id}/phone`, params)
  helpNotification = async id => this.httpClient().post(`${this.baseUri()}/${id}/help_notification`)
  sendSms = async ({ id, params }) =>
    this.httpClient().post(`${this.baseUri()}/${id}/send_sms`, params)
  orderCountByStatus = async (country) =>
    this.httpClient().get(`${this.baseUri()}/order_count_by_status${country ? `?country=${country}` : ''}`)
})()
