export const locale = {
  // __GENERATED_TRANSLATIONS__
  callAnalytics: {
    callCentre: {
      title: 'Call centre',
      user: 'User',
      ordersCount: 'Orders count',
      took: 'New',
      callback: 'Call back',
      canceled: 'Canceled',
      trash: 'Trash',
      approve: 'Approve',
      approvePercent: 'Approve percent',
      quality: 'Quality',
      upsell: 'Upsell',
      cross: 'Cross',
      gift: 'Gift',
      coupon: 'Coupon',
    },
    warehouse: {
      title: 'Warehouse',
      packaging: 'Packaging',
    },
    logistic: {
      title: 'Logistic',
      shipped: 'Shipped',
      delivered: 'Delivered',
      ransom: 'No ransom',
    },
    finance: {
      title: 'Finance',
      averageCheck: 'Average check',
    },
    table: {
      dateTime: 'Date & time',
      offer: 'Offer',
      orderId: 'Order ID',
      user: 'User',
      status: 'Status',
      statusReason: 'Status reason',
      nextCall: 'Next call at',
      duration: 'Duration',
    }
  },
  orderLogs: {
    table: {
      date: 'Date and Time',
      user: 'User',
      action: 'Action',
      section: 'Section',
      parameter: 'Parameter',
      was: 'Was',
      became: 'Became',
      status: 'Status',
    }
  },
  reportTemplate: {
     listTitle: 'Report template',
     editFormTitle: 'Update report template',
     createFormTitle: 'Create report template',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     table: {
       id: 'ID',
       name: 'Title',
       actions: 'Actions',
     },
     field: {
       name: 'Title',
       roles: 'Roles',
       reportsList: 'Report list',
       users: 'Users',
       filters: 'Filters',
       addToLogistic: 'Add to Logistic order list',
       columns: 'Columns',
     },
  },
  invoiceTranslations: {
     listTitle: 'Invoice translations',
     editFormTitle: 'Update translation',
     createFormTitle: 'Create translation',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     table: {
       id: 'ID',
       country: 'Country',
       actions: 'Actions',
     },
     field: {
       supplier: 'Supplier',
       recipient: 'Recipient',
       approveDate: 'Approve date',
       invoiceDate: 'Invoice date',
       paymentType: 'Payment type',
       productName: 'Product name',
       secondColumn: 'Second column',
       clearPrice: 'Clear price',
       quantity: 'Quantity',
       orderNumber: 'Order number',
       priceWithoutTax: 'Price without tax',
       percentColumn: 'Percent column',
       taxPercent: 'Tax percent',
       totalPrice: 'Total price',
       baseTax: 'Base tax',
       priceWithTax: 'Price with tax',
       delivery: 'Delivery',
       total: 'Total',
       totalWithTax: 'Total with tax',
       wordPrice: 'Price',
       country: 'Country',
     },
  },
   
  invoiceConstructor: {
     listTitle: 'Invoice constructor',
     editFormTitle: 'Update invoice',
     createFormTitle: 'Create invoice',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     table: {
       id: 'ID',
       name: 'Title',
       country: 'Country',
       warehouses: 'Warehouses',
       actions: 'Actions',
     },
     field: {
       name: 'Name',
       country: 'Country',
       warehouses: 'Warehouses',
       header: 'Header',
       supplier: 'Supplier',
       recipient: 'Recipient',
       title: 'Subheader',
       subtitle: 'Header for subheader',
       taxPercentage: 'Tax percentage',
       thankYouText: '"Thank you" text',
       phones: 'Phone number',
       additionalInfo: 'Additional info',
       locale: 'Invoice language',
       generateBarcode: 'Generate barcode',
       includeShippingCost: 'Include shipping cost',
       showOrderNumber: 'Show order number',
       showDeliveryPrice: 'Show delivery price',
     },
  },
  operatorOrderTimeReport: {
     listTitle: 'Operator activity',
     editFormTitle: 'Update substatus',
     createFormTitle: 'Create substatus',
     formFieldsBlockTitle: 'Main',
     formCreateUpdateSubmitErrorMessage: 'Request error',
     table: {
       id: 'ID',
       actions: 'Actions',
     },
     field: {
       name: 'Title*',
       active: 'Status',
     },
  },
   
  currencyRate: {
    listTitle: 'Currency rates',
    historyCreate: 'Create currency rate',
    historyUpdate: 'Update currency rate',
    editFormTitle: 'Update substatus',
    createFormTitle: 'Create substatus',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    table: {
      id: 'ID',
      currency: 'Currency code',
      rate: 'Currency rate value',
      date: 'Actual from',
      createdBy: 'Created by',
      actions: 'Actions',
    },
    field: {
      currency: 'Currency code',
      rate: 'Currency rate',
      date: 'Date',
    },
  },

  callCenterWebs: {
    listTitle: 'Webs',
    editFormTitle: 'Update web',
    createFormTitle: 'Create web',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    table: {
      id: 'ID',
      outerId: 'Outer ID',
      name: 'Title',
      actions: 'Actions',
    },
    field: {
      outerId: 'Outer ID',
      name: 'Title',
    },
  },
  filterPreset: {
    create: 'Create new preset',
    save: 'Save new preset',
    noPresets: 'You have no presets yet',
    noPresetsFound: 'No presets found',
    searchPlaceholder: 'Search by presets...',
    success: 'Preset "{title}" saved successfully',
  },
  productTypes: {
    main: 'Main',
    cross_product: 'Additional in set',
    gift: 'Gift',
    coupon: 'Coupon',
  },
  messagesStatistic: {
    pageTitle: 'SMS statistics',
    listTitle: 'Sent SMS statistics',
    id: 'ID',
    date: 'Date and time',
    orderId: 'Order ID',
    offer: 'Offer',
    number: 'Number',
    sms: 'SMS',
    user: 'User',
  },
  operatorStatuses: {
    listTitle: 'Operator statuses',
    editFormTitle: 'Update substatus',
    createFormTitle: 'Create substatus',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    resetOperatorStatus: 'Reset status',
    table: {
      name: 'Operator',
      groups: 'Operator groups',
      country: 'Country',
      status: 'Status',
      actions: 'Actions',
    },
    statuses: {
      offline: 'Offline',
      not_available: 'Unavailable',
      wait: 'Awaiting',
      call: 'Call',
      application_processing: 'Application processing',
      personal_break: 'Personal break',
      technical_break: 'Technical break',
      manual_call: 'Manual calls',
      education: 'Product training',
      kln: 'QA training',
      consultation: 'Consultation',
      order_processing: 'Order processing',
    },
  },
  statuses: {
    accepted: 'New order',
    callback: 'Callback',
    approved: 'Approved',
    cancelled: 'Cancelled',
    shipped: 'Sent',
    delivered: 'Delivered',
    no_ransom: 'Return',
  },
  callbackReasons: {
    busy: 'Number is busy',
    no_answer: 'No answer',
    disconnented: 'Call interrupted',
    no_free_operators: 'No available operators',
    technical_error: 'Technical error',
    operator_did_not_answer: 'Operator didn\'t answer',
    ask_callback: 'Client request',
    send_later: 'Send later',
    specify_info: 'Refine information',
    number_not_available: 'Number is unreachable',
  },
  orderSubStatuses: {
    listTitle: 'Substatuses',
    editFormTitle: 'Update substatus',
    createFormTitle: 'Create substatus',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    countriesSms: 'Countries and SMS templates',
    autoSendSms: 'Send SMS automatically',
    hasTracker: 'Has tracker',
    table: {
      id: 'ID',
      name: 'Title',
      smsTemplates: 'SMS templates',
      cpaText: 'Text for CPA',
      statusDS: 'DS Status',
      status: 'Applicable to statuses',
      isOperator: 'Is used by operator',
      actions: 'Actions',
    },
    field: {
      name: 'Title',
      status: 'Applicable to statuses',
      cpaText: 'Text for CPA',
      sdStatus: 'DS status',
      isOperator: 'The reason is set by the operator',
    },
  },

  writeOffHistory: {
    listTitle: 'Write-Off History',
    editFormTitle: 'Update write-off history',
    createFormTitle: 'Create write-off history',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    table: {
      id: 'ID',
      title: 'Order №',
      product: 'Product',
      amount: 'Amount',
      country: 'Country',
      warehouse: 'Warehouse',
      date: 'Write-off date',
      actions: 'Actions',
    },
    field: {
      name: 'Title*',
      active: 'Status',
    },
  },
  postalCodes: {
    listTitle: 'Postal codes',
    editFormTitle: 'Update postal code',
    createFormTitle: 'Create postal code',
    importFormTitle: 'File import',
    formFieldsBlockTitle: 'Main',
    formCreateUpdateSubmitErrorMessage: 'Request error',
    table: {
      id: 'ID',
      title: 'Title',
      country: 'Country',
      regionCode: 'Region code',
      code: 'Postal code',
      region: 'State',
      districts: 'Colonia (Distrito)',
      ubigeo: 'UBIGEO',
      deliveryService: 'Delivery service',
      deliveryTerm: 'Delivery term',
      warehouse: 'Warehouse',
      errors: 'Information',
      actions: 'Actions',
    },
    field: {
      name: 'Title',
      nameReq: 'Title*',
      active: 'Status',
    },
  },
  filters: {
    all: 'All',
    activity: 'State',
    operator: 'Type: Operator',
    terminalPayment: 'Card payment',
    country: 'Country',
    countries: 'Countries',
    countryReq: 'Country*',
    countriesReq: 'Countries*',
    region: 'State',
    regions: 'States',
    regionReq: 'State*',
    regionsReq: 'States*',
    deliveryService: 'Delivery service',
    deliveryServices: 'Delivery services',
    deliveryServiceReq: 'Delivery service*',
    deliveryServicesReq: 'Delivery services*',
    warehouse: 'Warehouse',
    warehouses: 'Warehouses',
    warehouseReq: 'Warehouse*',
    warehousesReq: 'Warehouses*',
  },
  settings: {
    saveSuccess: 'Data successfully updated',
    uploadSuccess: 'Data uploaded successfully!',
  },

  // crm-sky
  base: {
    noPageToNavigate: 'No page to navigate to',
    leaveWithoutSave: 'Leave without save',
    preset: 'Presets',
    period: 'Period',
    importData: 'Import data',
    hideCols: 'Hide columns',
    save: 'Save',
    cancel: 'Cancel',
    remove: 'Remove',
    delete: 'Delete',
    create: 'Create',
    confirm: 'Confirm',
    loadFromFile: 'Upload from file',
    areYouSureToDelete: 'Are you sure to deactivate?',
    areYouSureToDeactivate: 'Are you sure to deactivate?',
    areYouSureToActivate: 'Are you sure to activate?',
    details: 'Details',
    logs: 'Logs',
    yes: 'Yes',
    no: 'No',
    add: 'Add',
    edit: 'Edit',
    creating: 'New',
    editing: 'Edit',
    time: 'Time',
    back: 'Back',
    filters: 'Filters',
    apply: 'Apply',
    selectAll: 'Select all',
    clearAll: 'Clear all',
    deactivate: 'Deactivate',
    activate: 'Activate',
    accepted: 'New order',
    processing: 'Processing',
    incorrect: 'Invalid',
    approved: 'Approved',
    rejected: 'Rejected',
    search: 'Search',
    noData: 'No data',
    all: 'All',
    allCount: 'All ({count})',
    onlyChosen: 'Only selected',
    attention: 'Warning',
    fill: 'Fill',
    validationError: 'Validation error',
    validationErrorServer: 'Server error',
    deliveryServiceAndWarehouseCitiesValidationError:
      'Add or remove fields in "Delivery service and warehouse"',
    noMoreItems: 'End of list',
    noElementsFound: 'Nothing found',
    import: 'Import',
    resetFilters: 'Reset filters',
    createdAt: 'Created at',
    createdBy: 'Created by',
    actions: 'Actions',
    city: 'City',
    district: 'District',
    region: 'State',
    country: 'Country',
    today: 'Today',
    yesterday: 'Yesterday',
    currentMonth: 'Current month',
    noDataAvailable: 'No data available',
    startTypingText: 'Start typing',
    listStatusActive: 'Active',
    listStatusInactive: 'Inactive',
    change: 'Edit',
    day: 'Days',
    hour: 'Hours',
    minute: 'Minutes',
    minutes: 'minutes',
    seconds: 'seconds',
    toTheBeginning: 'To start',
    warning: 'Warning',
    choosePeriod: 'Choose period',
    filtersActive: 'Active',
    filtersDeactivated: 'Deactivated',
    filtersAll: 'All',
    selectFields: 'Select columns',
    viewAll: 'View all',
    open: 'Collapse',
    expand: 'Expand',
    close: 'Close',
    reset: 'Reset',
    operator: 'Operator',
    notOperator: 'Not operator',
    export: 'Export',
    exportXls: 'Export',
    images: 'Images',
    product: 'Product',
    selected: 'Selected',
    items: 'items',
    onPage: 'On page',
    paginationOnPage: 'On page',
    paginationFrom: 'from',
    history: 'History',
    field: 'Field',
    rub: 'rub',
    uah: 'uah',
    mdl: 'mld',
    success: 'Success',
    comments: 'Comments',
    choose: 'Select',
    value: 'Value',
    role: 'Role',
    group: 'Group',
    month: 'Month',
    niche: 'Niche',
    total: 'Total',
    from: 'from',
    mobileView: 'Please, use your desktop device or make the browser window wider',
    goToMain: 'Home page',
    showNumber: 'Show phone',
    failedToLoad: 'Uploading failed',
    failedToLoadDescription: 'An error occurred. Try to reload the page.',
    limitText: '{count} more',
    addSelectedOptions: 'Add selected',
    removeSelectedOptions: 'Exclude selected',
    ok: 'Ok',
    selectDate: 'Select date',
    selectMonth: 'Select month',
    presets: {
      today: 'Today',
      todayAndYesterday: 'Today and yesterday',
      yesterday: 'Yesterday',
      nextWeek: 'Current week',
      currentWeek: 'Current week',
      previousWeek: 'Last week',
      currentMonth: 'Current month',
      previousMonth: 'Last month',
      currentYear: 'Current year',
    },
    beWaiting: 'Wait',
    dataLoading: 'Data processing',
    copyPrefix: 'Copy',
    copy: 'Copy',
    language: 'Language',
    exit: 'Exit',
  },
  call: {
    order: 'Order',
    offer: 'Offer',
    call: 'Call',
    answer: 'Answer',
    reject: 'Reject',
    voice: 'Voice',
    callbackList: 'Callback list',
    callRecord: 'Call recording',
    recordError:'The call recording is no longer available because it was deleted from the server',
    wrongDate: 'Invalid date',
    aboveMaxAge: 'Invalid date (more than 120 years)',
    datePlaceholder: 'dd/mm/yyyy',
  },
  dashboard: {
    main: 'Dashboard',
    operator: 'Operators dashboard',
    applications: 'Orders',
    operatorOrders: 'Operator orders',
    suspiciousOrders: 'Suspicious orders',
    loadFromFile: 'Upload from file',
    calls: 'Calls',
    controlList: 'QA assessment',
    klnOperator: 'Operator QA results',
    ccStatisticsDashboard: 'Call center resources',
    questioning: 'Surveys',
    normsAndGrades: 'Norms and grades',
    normsAndGradesSub: {
      operatorGrades: 'Operator grades',
      offerNorms: 'Offer norms',
    },
    missedCalls: 'Missed calls',
    tickets: 'Tickets',
    myTickets: 'My tickets',
    potentialTrash: 'Trash',
    potentialTrashSub: {
      notProcessed: 'New trash',
      processed: 'Processed',
      disputable: 'Disputed',
    },
    callCampaigns: 'Campaigns',
    smsCampaigns: 'SMS campaigns',
    repeatedConfirm: 'Reconfirmations',
    stats: 'Statistics',
    statsSub: {
      operatorsTimeTracking: 'Operators time tracking',
      incorrectStats: 'Invalid report',
      fraudSpam: 'Fraud/Spam',
      statsCC: 'Call center report',
      statsRepeat: 'RC report',
      checkDistribution: 'Orders values distribution',
      statsKLN: 'QA report',
      approveSpeed: 'Time till Approved',
      statsRejected: 'Rejected report',
      statsCanceled: 'Canceled report',
      statsCall: 'Calls statistics',
      statsCallOld: 'Calls statistics (old)',
      dostavimReport: 'Post/Courier report',
    },
    logs: 'Log',
    logsSub: {
      loginLogs: 'Authentication log',
      orderLogs: 'Orders log',
      watchNumberLogs: 'Orders/phones views',
      accountsLog: 'Accounts log',
    },
    reportsExport: 'Export reports',
    settings: 'Settings',
    courierDelivery: 'Courier delivery',
    pointOfIssue: 'Delivery point',
    post: 'DS analytics',
    troubleDelivery: 'Delivery failures',
    deliveryServiceAnalytic: 'DS analytics',
    financialReport: 'Financial report',
    dynamic: 'Dynamics',
    averageBills: 'Average order value',
    ransomByDay: 'Delivered by days',
    dynamicRansomByDay: 'Delivered by days',
    hungRansom: 'Delivery services debts',
    buybackDynamics: 'Delivered by days',
    effOfAdditProjects: 'Cold sales efficiency',
    finDepLog: 'Financial log',
    ccPrepayment: 'Call center salaries',
    ccPrice: 'Call center expenses',
    ccPriceSub: {
      operatorSalary: 'Operator salaries',
      prepaidOper1: 'Operator 2 prepayment',
      prepaidOper2: 'Operator 2 prepayment',
      operatorVacations: 'Operator holidays',
      teamLeadSalary: 'Team leader salaries',
      prepaidTeamLead1: 'Team leader 1 prepayment',
      prepaidTeamLead2: 'Team leader 2 prepayment',
    },
    notificationsPrice: 'Notification expenses',
    logisticPrice: 'Logistics expenses',
    logisticSalary: 'Logistics salaries',
    logisticPriceSub: {
      rpSalary: 'Logistics managers',
      officeSalary: 'Logistics back office',
      courierSalary: 'Couriers',
      hubHelpersSalary: 'Warehouse assistants',
    },
    comissionsAndTaxes: 'Fines and taxes',
    websPayouts: 'Affiliate payments',
    legalPersonsStats: 'Companies report',
    penalty: 'Operator fines and bonuses',
    operatorSchedule: 'Call center work schedule',
    smsChain: 'Notification chains',
    autoCall: 'Autodialing',
  },
  uploadArea: {
    maxSize: 'Max file size {maxSize} MB',
    moveYourFileOr: 'Drag and drop your file or',
    upload: 'upload',
    limit: 'Max files quantity - {limit}',
    invalidType: 'Invalid file type',
  },
  lang: {
    ru: 'Russian',
    en: 'English',
  },
  authLogin: {
    username: 'Email',
    password: 'Password',
    loginIn: 'Login',
    codeConfirm: 'Proceed',
    invalidCredentials: 'Invalid or expired key!',
    forgetPassword: 'Forgot password?',
    welcome: 'Welcome!',
    twoFactorTitle: 'Two-factor authentication settings',
    twoFactorDescription:
      'Scan this QR-code with Google Authenticator app. Enter 6-digit key from the app.',
    qrCode: 'Code from app',
    googleCode: 'Google Authenticator Code',
    step: 'Step',
    step1SubTitle: 'Download app',
    step2SubTitle: 'Scan QR-code',
    step3SubTitle: 'Enter authentication code',
    step1SubDescription: 'Download Google Authenticator (Android - IOS) on your phone',
    step2SubDescription: 'Open application and press В«+В»',
    step3SubDescription: 'Scan QR-code and enter 6-digit key from application',
    errorComparePassword: 'Password does not meet the requirements',
    usedPassword: 'The password was used before',
    errorPassword: 'Invalid password',
    errorLogin: 'Invalid email',
  },
  authRestore: {
    email: 'Email',
    forgetPassword: 'Password reset',
    authRestoreDesc: 'Enter your email:',
    send: 'Send',
    checkYourEmail: 'Check your mail',
    sentDesc: 'Email with instructions to reset password is sent to',
    isNotFounded: 'No account with entered email found.',
    wrongEmail: 'Wrong email?',
    reEnter: 'Re-enter!',
  },
  authRecoveryPassword: {
    createNewPassword: 'Create password',
    create: 'Create',
    first: 'New password',
    second: 'Enter again',
    titleOfDemands: 'Your password must contain:',
    lengthDemand: 'min 8 characters',
    capitalDemand: 'one upper case letter',
    lowercaseDemand: 'one lower case letter',
    numberDemand: 'one digit (0-9)',
    symbolDemand: 'one symbol (!@#%&*.)',
    example: 'Example',
    exampleSymbols: 'Vtlq3*fsdfhqwj*',
    length: '8',
    generatePassword: 'Generate password',
    sameField: 'Passwords do not match',
  },
  userGroup: {
    name: 'Title',
    typeGroup: 'Group type',
    newTypeGroup: 'New group type',
    main: 'General',
    groupOfUsers: 'User groups',
    listOfUsers: 'User list',
    operatorRoom: 'Operators group',
    newUserGroup: 'New group',
    actions: 'Actions',
    countUsers: 'Users',
    manager: 'Team leader/Manager',
    users: 'Users',
    user: 'User',
    show: 'Show',
    groups: 'Groups',
    addUserType: 'Add group type',
    itemsTypeShow: {
      all: 'All ({count})',
      onlyChosen: 'Only selected',
    },
    createUserGroup: 'User group',
    editUserGroup: 'User group',
    active: 'State',
    onBreakCount: 'Max users on break',
    saleType: 'Sales type',
    operator: 'Operators group',
    distributionPriority: 'Distribution priority',
    ascPriority: 'From oldest to newest',
    descPriority: 'From newest to oldest',
  },
  userGroupType: {
    name: 'Title',
  },
  user: {
    users: 'Users',
    newUser: 'New user',
    fullName: 'Name',
    email: 'Email',
    actions: 'Actions',
    name: 'Name',
    lastName: 'Surname',
    webs: 'Webs',
    main: 'General',
    password: 'Password',
    languages: 'Language',
    countries: 'Country',
    schedule: 'Working hours',
    groups: 'Groups',
    groupTypes: 'Group types',
    offer: 'Offer',
    dataAccess: 'Permissions',
    role: 'Role',
    permissions: 'Permissions',
    createUser: 'User',
    editUser: 'User',
    setUpAccessForRoleDesc: 'Role template settings',
    operator: 'Operator',
    workStartDate: 'Employment date',
    dismissalDate: 'Dismissal date',
    bonusCurrency: 'Salary currency',
    loginCountry: 'Login country',
    offers: 'Offers',
    isOperator: 'Operator',
    grade: 'Grade',
    cardNumber: 'Main bank account',
    additionalCardNumber: 'Additional bank account',
    salaryType: 'Role for salary calculation',
    actionsSuccess: 'Users edited',
    activateSuccess: 'Users activated: {count}',
    deactivateSuccess: 'Users deactivated: {count} ',
    lastLoginAt: 'Last login',
    checkEmail: 'Check if email is correct:',
    notAvailable: 'Unavailable',
    teamLeaderCC: 'Team leader CC',
    localLeaderCC: 'Local head of CC',
    operatorCC: 'Operator CC',
    emailSent: 'Email sent successfully',
  },
  userRole: {
    userRoles: 'Roles and permissions',
    newUserRole: 'New role',
    name: 'Title',
    actions: 'Actions',
    roleAccess: 'Role permissions',
    settingAccessRole: 'Role template settings',
    createUserRole: 'Role',
    editUserRole: 'Role',
    userCount: 'Active users',
    addSecuritySettings: 'Extra security settings',
    logout: 'Logout if the status is "Unavailable"',
    maxView: 'Max phone views per day',
    showFullPhone: 'Show full phone number',
  },
  offerGroup: {
    name: 'Title',
    status: 'Status',
    main: 'General',
    offer: 'Offer',
    offers: 'Offers',
    groupOfOffers: 'Offer groups',
    newOffer: 'New offer group',
    actions: 'Actions',
    createOfferGroup: 'Offer groups',
    editOfferGroup: 'Offer groups',
  },
  error: {
    invalid: '{name} is invalid',
    required: '{name} is required',
    requiredIf: '{name} is required',
    callUnavailable: 'The call recording is no longer available because it was deleted from the server',
    email: 'Wrong email format',
    maxLength: 'Max length {length}',
    minLength: 'Min length {length}',
    maxValue: 'Max value: {value}',
    minValue: 'Min value: {value}',
    onlyNumbers: 'Only digits allowed',
    onlyIntegerNumbers: 'Only digits allowed',
    onlyWords: 'Only letters allowed',
    allow: 'Password must contain: ',
    sameField: 'Passwords do not match',
    regexPassword: 'Wrong password format',
    labelsLimit: 'You can request up to {limit} labels at a time',
    url: 'Wrong URL',
    dateFromLess: 'Date "from" must be earlier than "to"',
    dateToLess: 'Date "to" must be later than "from"',
    commaSpaceRule: 'The beginning and end of field must not contain space or comma',
    lengthDemand: 'min 8 characters',
    capitalDemand: 'one upper case letter',
    lowercaseDemand: 'one lower case letter',
    numberDemand: 'one digit <span class="black--text text--lighten-2">(0-9)</span>',
    symbolDemand: 'one symbol <span class="black--text text--lighten-2">(!@#%&*.)</span>',
    latinDemand: 'Password must not contain Cyrillic letters',
    invalidValue: 'Invalid value error',
    notBeEmpty: 'Empty value error',
    error403Title: 'Something went wrong...',
    error403Desc: 'Page does not exist, or you do not have permission to access it',
    error404Title: 'Something went wrong...',
    error404Desc: 'Page does not exist, or you do not have permission to access it',
    emailExist: 'Account with this email already exists',
    macrosExist: 'Entity with the same title already exists',
    entityExists: 'An entity with the same name already exists',
    errorComparePassword: 'Enter password you have not used before',
    systemUpdate: 'System Update',
    systemUpdateDesc: 'A new version of the system has been deployed. To ensure optimal performance and to load the latest features, please refresh your page.',
    refreshPage: 'Refresh page',
  },

  page: {
    settingsDashboard: 'Settings',
    callCentreDashboard: 'Operator dashboard',
    pageSettingsCountry: 'Countries',
    pageLanguageList: 'Language',

    login: 'Authentication',
    restore: 'Forgot your password?',
    qrCode: 'Two-factor authentication settings',
    recoveryPassword: 'Reset password',
    createPassword: 'Create password',

    pageMain: 'Home page',

    pageFinanceList: 'Financial settings',
    pageFinanceCreate: 'Financial settings',
    pageFinanceEdit: 'Financial settings',
    pageFinanceDashboard: 'Country settings',

    pageUserList: 'Users',
    pageUserCreate: 'User',
    pageUserEdit: 'User',

    pageUserGroupList: 'User groups',
    pageUserGroupCreate: 'User group',
    pageUserGroupEdit: 'User group',

    pageProductList: 'Products',
    pageProductCreate: 'Product',
    pageProductEdit: 'Product',

    pageProductTypeList: 'Product types',
    pageProductTypeCreate: 'Product type',
    pageProductTypeEdit: 'Product type',
    pageProductTypeDashboard: 'Product type',

    pageIpAddressList: 'IP addresses',
    pageIpAddressCreate: 'IP address',
    pageIpAddressEdit: 'IP address',
    pageIpAddressDashboard: 'IP address',

    pageOfferList: 'Offers',
    pageOfferCreate: 'Offer',
    pageOfferEdit: 'Offer',

    pageOfferGroupList: 'Offer groups',
    pageOfferGroupCreate: 'Offer groups',
    pageOfferGroupEdit: 'Offer groups',

    pageOfferNicheList: 'Niches',
    pageOfferNicheCreate: 'Niche',
    pageOfferNicheEdit: 'Niche',
    pageOfferNormsList: 'Offer norms',

    pageUserRoleList: 'Roles and permissions',
    pageUserRoleCreate: 'Roles and permissions',
    pageUserRoleEdit: 'Roles and permissions',
    pageUserRoleDashboard: 'Roles and permissions',

    pageLeadList: 'Leads',
    pageLeadCreate: 'Lead',
    pageLeadEdit: 'Leads',

    pageRejectReasonList: 'Status reasons',
    pageRejectReasonCreate: 'Status reason',
    pageRejectReasonEdit: 'Status reason',

    pageOrderList: 'Orders',
    pageOrderCreate: 'Order',
    pageOrderEdit: 'Order',

    pageOrderOperatorList: 'Operator orders',
    pageOrderOperatorCreate: 'Operator orders',
    pageOrderOperatorEdit: 'Operator orders',

    pageOrderImport: 'Upload from file',

    pageOrderTagList: 'Tags',
    pageOrderTagCreate: 'Tags',
    pageOrderTagEdit: 'Tags',
    pageOrderTagDashboard: 'Tags',

    pageQuestionnaireKnl: 'QA assessment',
    pageQuestionnaireKnlEdit: 'QA assessment',

    pageKlnAssessmentList: 'QA assessments',
    pageKlnAssessmentCreate: 'QA assessment',
    pageKlnAssessmentEdit: 'QA assessment',

    pageKlnStatistic: 'QA report',

    pageKlnOperatorAssessmentList: 'QA assessments',
    pageKlnOperatorAssessmentCreate: 'QA assessment',
    pageKlnOperatorAssessmentEdit: 'QA assessment',

    pageMetashipAccounts: 'Delivery aggregator accounts',
    pageMetashipAccountsCreate: 'Delivery aggregator account',
    pageMetashipAccountsEdit: 'Delivery aggregator account',

    pageCityList: 'Cities',
    pageCityCreate: 'City',
    pageCityEdit: 'City',

    pageProductGiftList: 'Catalogs',
    pageProductGiftCreate: 'Catalog',
    pageProductGiftEdit: 'Catalog',

    pageProductSetsList: 'Product sets',
    pageProductSetsCreate: 'Product sets',
    pageProductSetsEdit: 'Product sets',

    pageTicketTypesList: 'Ticket types',
    pageTicketTypesCreate: 'Ticket type',
    pageTicketTypesEdit: 'Ticket type',

    pageGroupTypesList: 'User group types',
    pageGroupTypesCreate: 'User group types',
    pageGroupTypesEdit: 'User group types',
    pageGroupTypesDashboard: 'Group types',

    pageSalesScriptList: 'Scripts',
    pageSalesScriptCreate: 'Script',
    pageSalesScriptEdit: 'Script',

    pageSalesScriptTemplateList: 'Script chapters templates',
    pageSalesScriptTemplateCreate: 'Template',
    pageSalesScriptTemplateEdit: 'Template',

    pagePhoneCodesList: 'Phone codes',
    pagePhoneCodesCreate: 'Phone code',
    pagePhoneCodesEdit: 'Phone code',

    pageDeliveryPointsList: 'Delivery points',
    pageDeliveryPointsCreate: 'Delivery point',
    pageDeliveryPointsEdit: 'Delivery point',
    pageDeliveryPointsImport: 'Import Delivery points',

    pageDeliveryIntervalList: 'Delivery intervals',
    pageDeliveryIntervalCreate: 'Delivery interval',
    pageDeliveryIntervalEdit: 'Delivery interval',

    pageLandingList: 'Affiliate websites',
    pageLandingCreate: 'Affiliate website',
    pageLandingEdit: 'Affiliate website',

    PageDeliveryWeekendList: 'Holidays',
    PageDeliveryWeekendCreate: 'Holiday',
    PageDeliveryWeekendEdit: 'Holiday',

    pageStorages: 'Warehouses',
    pageSuppliers: 'Suppliers',
    pageDeliveryService: 'Delivery services',
    pageDeliveryServiceCreate: 'Delivery service',
    pageDeliveryServiceEdit: 'Delivery service',

    pageStopWordsList: 'Stop words',

    pageOrganization: 'Companies',

    pageBlackPhoneList: 'Blacklist',
    pageBlackPhoneCreate: 'Blacklist',
    pageBlackPhoneEdit: 'Blacklist',

    pageTicketsList: 'Tickets',
    pageTicketCreate: 'Ticket',
    pageTicketEdit: 'Ticket',

    pageMyTicketsList: 'My tickets',
    pageMyTicketCreate: 'Ticket',
    pageMyTicketEdit: 'Ticket',

    pageDeliveryWarehouseList: 'Warehouses',
    pageDeliveryWarehouseCreate: 'Warehouse',
    pageDeliveryWarehouseEdit: 'Warehouse',
    pageDeliveryWarehouseImport: 'Import the remainder',

    pageRegionList: 'States',
    pageRegionCreate: 'State',
    pageRegionEdit: 'State',

    pageOrderFraudList: 'Invalid orders report',
    pageApproveSpeed: 'Time till approve report',

    pageWorkTimeList: 'Operator time tracking',
    pageWorkTimeMeta: 'Operators efficiency',
    pageLimitTimeEdit: 'Operator time limits',

    pageLogAuthList: 'Authentication log',
    pageLogOrderList: 'Orders log',

    pageQuestionnairesList: 'Surveys',
    pageQuestionnairesCreate: 'Survey',
    pageQuestionnairesEdit: 'Survey',

    pageQuestionnairesOrderList: 'Survey',
    pageLegalPersonsStats: 'Companies statistics',

    pageSalaryBonusList: 'Call center salary matrix',
    pageSalaryBonusDashboard: 'Call center salary matrix',
    pageSalaryBonusImport: 'Import Call center salaries',
    // Р·Р°РіР»СѓС€РєРё РїРѕРєР° С‡С‚Рѕ
    pageLogs: 'Log',
    pageTagsToOrders: 'Tags',
    pageQuestionnaires: 'Surveys',

    pageOrderTrash: 'New trash',
    pageOrderTrashChecked: 'Processed trash',
    pageOrderTrashDispute: 'Disputed',

    pageOperatorGrades: 'Operator grades',

    main: 'General',
    general: 'General',
    callCentre: 'Call center',
    logistics: 'Logistics',
    finance: 'Finances',

    pageCampaign: 'Campaigns',
    pageCampaignCreate: 'Campaign',
    pageCampaignCreateManual: 'Manual campaign',
    pageCampaignEdit: 'Campaign',

    pageRepeatedConfirm: 'Reconfirmations',
    pageRepeatedConfirmCreate: 'Reconfirmations',
    pageRepeatedConfirmEdit: 'Reconfirmations',

    pageCityImport: 'Import Cities',

    pageCurrencyConversion: 'Currency rates',
    pageCurrencyConversionDashboard: 'Currency rate',
    pageCurrencies: 'Currencies',

    pageSmsTemplate: 'SMS templates',
    pageSmsTemplateCreate: 'SMS template',
    pageSmsTemplateEdit: 'SMS template',

    pageDistrictList: 'Districts',
    pageDistrictCreate: 'District',
    pageDistrictEdit: 'District',

    exportReports: 'Reports export',
    exportReportsList: 'Reports list',

    pageUserPenaltyList: 'Operator fines ',
    pageUserPenaltyCreate: 'Penalty',
    pageUserPenaltyEdit: 'Penalty',

    pageManagerDashboard: 'Dashboard',
    pageLogisticsManagerDashboard: 'Management dashboard',
    pageCallCentreManagerDashboard: 'Management dashboard',

    pageSmsCampaign: 'SMS campaigns',
    pageSmsCampaignManual: 'Manual SMS campaign',
    pageSmsCampaignAuto: 'SMS campaign',

    pageDelivery: 'Delivery',
    pageDeliveryCourier: 'Courier delivery',
    pageDeliveryPoints: 'Delivery point',
    pageDeliveryPost: 'Post',

    pageSalesModelList: 'Sales model',
    pageSalesModelDashboard: 'Sales models',

    pageCostCC: 'Call center expenses',
    pagePrepayment: 'Operator 1 prepayment',
    pagePrepaymentTwo: 'Operator 2 prepayment',

    pagePrepaymentTeamLead: 'Team leader 1 prepayment',
    pagePrepaymentTeamLeadTwo: 'Team leader 2 prepayment',

    pageUserSalary: 'Operators salaries',

    pageEfficiencyOfAdditionalProjects: 'Cold sales efficiency',

    pageWebAnalytica: 'Affiliate payments',

    pagePaymentHoldList: 'Fines',
    pagePaymentHoldCreate: 'Fine',
    pagePaymentHoldEdit: 'Fine',

    pagePaymentProfitList: 'Bonuses',
    pagePaymentProfitCreate: 'Bonus',
    pagePaymentProfitEdit: 'Bonus',

    pageExcludedOffersForSDList: 'Excluded offers for Delivery service',
    pageExcludedOffersForSDCreate: 'Offer for Delivery service',
    pageExcludedOffersForSDEdit: 'Offer for Delivery service',

    pageNotFound: '404',

    pageError403: 'Access denied',
    pageError404: 'Page not found',

    pageHangingRansom: 'Delivery services debts',
    pageUnpaidOrders: 'Orders with DS debt',

    pageDistrictImport: 'Import Districts',
    pageRegionImport: 'Import States',

    pageAverageCheck: 'Average orders value',

    pageDistributionCheck: 'Orders values distribution',

    pageBuybackDynamics: 'Delivered by days',

    pageRejectedStatistics: 'Rejected orders statistics',

    pageCallController: 'Calls',

    pageCallAnalytics: 'Call analytics',

    pageDeliveryServiceAnalytic: 'Delivery services report',

    pageCallCentreStatistics: 'Call center report',

    pageOperatorEfficiency: 'Operator efficiency',

    pageOperatorStatistics: 'Operator report',

    pageSuspiciousOrdersList: 'Suspicious orders',

    pageReconfirmationStatistic: 'Reconfirmations',

    pageCallStatistics: 'Calls statistics',

    pageCallStatisticsOld: 'Calls statistics (old)',

    pageCcDashboardStatistics: 'Call center resources',

    pageDeliveryTaxList: 'Fines and taxes',

    pageLogisticPrice: 'Logistics expenses',
    pageLogisticPriceImport: 'Import Logistics expenses',

    pageCallCenterPrice: 'Call center expenses',

    pageCallCenterPriceImport: 'Import Call center expenses',

    pageDeliveryTaxImport: 'Import Fees and taxes',

    pageFinancialReport: 'Financial report',

    pageFinancialOrdersReport: 'Orders for fin.report',

    pagePermission: 'Permissions control',
    pageQuestionnairesOrderEdit: 'Survey',
    pageViewPermission: 'Permissions control',

    pageDostavim: 'Post/Courier delivery report',

    pagePenaltyList: 'Operator fines and bonuses',

    pageNoticeDepartment: 'Notifications',

    pageLogisticsOrders: 'Logistics',

    pageLogWatchNumberList: 'Orders/phones views log',

    pageLogAccounts: 'Accounts log',

    pageLogAbnormalList: 'Suspicious views',

    pageUserScheduleList: 'Operator schedule options',
    pageUserScheduleListShort: 'Operator schedule options',

    pageUserScheduleCreate: 'Operator schedule options',

    pageOperatorSchedule: 'Operator work schedule',

    pageSmsSettings: 'SMS settings',

    pageAutocall: 'Autodialing',
    pageSmsChain: 'Notification chains',
    pageSmsChainCreate: 'New notification chain',
    pageSmsChainEdit: 'Notification chain',
  },
  country: {
    status: 'Status',
    country: 'Country',
    countries: 'Countries',
    create: 'Create country',
    editCountry: 'Edit country',
    code: 'Code',
    rowsOn: 'Show',
    phoneCode: 'Phone code',
    activity: 'State',
    form: {
      name: 'Name',
      countryCode: 'Country code',
      phoneCode: 'Phone code',
    }
  },
  currencies: {
    title: 'Currencies',
    code: 'Code',
    currencyName: 'Currency',
    editing: 'Edit',
    errorFetchingCurrencies: 'Error fetching currencies',
  },
  languages: {
    languages: 'Language',
    status: 'Status',
    country: 'Country',
    code: 'Code',
    rowsOn: 'Show',
    name: 'Title',
  },
  productType: {
    main: 'General',
    name: 'Title',
    typeOneC: 'Corresponding warehouse item type',
    description: 'Description',
    addField: 'Add field',
    createProductType: 'Product type',
    composition: 'Composition',
    contraindications: 'Contraindications',
    editProductType: 'Product type',
    groupOfProducts: 'Product types',
    newProduct: 'New product',
    actions: 'Actions',
    updateList1C: 'Update list',
    successUpdate: 'Warehouse item types updated',
  },
  ipAddress: {
    main: 'General',
    name: 'Title',
    ipAddress: 'IP Address',
    actions: 'Actions',
  },
  product: {
    main: 'General',
    pictures: 'Images',
    products: 'Products',
    newProduct: 'New product',
    actions: 'Actions',
    idOneC: 'Outer ID',
    updateData: 'Update data',
    name: 'Title',
    type: 'Product type',
    alternativeName: 'Alternative title',
    typeOneC: 'Product type (CRM)',
    typeCallCenter: 'Call center product type',
    offers: 'Offers',
    scriptName: 'Title for scripts',
    country: 'Country',
    addPicture: 'Add image',
    general: 'General',
    additional: 'Extras',
    createProduct: 'Product',
    editProduct: 'Product',
    invalidProductOneC: 'ID not found',
    productTypeOneCBad: 'dietary supplement',
    productTypeOneCNormal: 'physical',
    activity: 'State',
    hideEmptyFields: 'Hide empty fields',
    showEmptyFields: 'Show empty fields',
    invalidFields: 'Empty required fields in "General" tab',
  },
  lead: {
    newLead: 'New Lead',
    actions: 'Actions',
    leads: 'Leads',
    name: 'Name',
    lastName: 'Surname',
    patronymic: 'Patronymic',
    gender: 'Gender',
    fullName: 'Full name',
    age: 'Age',
    phone: 'Phone number',
    male: 'Male',
    female: 'Female',
    clientData: 'Customer data',
    createLead: 'View',
    editLead: 'Edit',
  },
  offer: {
    general: 'General',
    main: 'General',
    products: 'Products',
    hotOffers: 'Offers',
    offers: 'Offers',
    newOffer: 'New offer',
    actions: 'Actions',
    active: 'Active',
    idOneC: 'Item warehouse ID',
    updateData: 'Synchronize',
    set: 'Set',
    cloneOffer: 'Clone offer',
    cloneOfferSuccess: 'Offer cloned successfully',
    name: 'Title',
    productSets: 'Product sets',
    removeSet: 'Remove set',
    setCost: 'Set cost',
    setCostItem: 'Item cost',
    deliveryCost: 'Delivery cost',
    alternativeName: 'Alternative title',
    groupOperators: 'Operator groups',
    operatorGrade: 'Operator grades',
    offerTargets: 'Offer targets',
    avgCheck: 'Average check',
    approveCheck: 'Approve check',
    approvePercent: 'Approve percent',
    ransomPercent: 'Ransom percent',
    niche: 'Niche',
    niches: 'Niches',
    product: 'Product',
    productType: 'Product type',
    mainProductType: 'Main product type',
    productCountry: 'Country',
    additional: 'Linked campaign offers',
    related: 'Linked offers',
    priorityOperators: 'Operators rating',
    groupOffers: 'Offer groups',
    addProduct: 'Add product',
    addProductSet: 'Add product set',
    createOffer: 'Offer',
    editOffer: 'Offer',
    salesType: 'Sales type',
    salesModel: 'Sales model',
    status: 'Status',
    companies: 'Campaigns',
    languages: 'Language',
    invalidFields: 'Empty required fields in "General" tab',
    offer: 'Offer',
    campaign: 'Campaign',
    tabPriorityOperators: {
      filterList: {
        inputUsers: 'Users',
        inputGrades: 'Grades',
        inputGroups: 'Operator groups',
        placeholder: 'Enter text',
      },
      tableList: {
        number: 'ID',
        name: 'Full name',
        grade: 'Grade',
        groups: 'Group',
        priority: 'Score',
        resetRating: 'Reset dialer ratings',
        editText: 'Edit',
      },
    },
  },
  dialogImageCropper: {
    addPicture: 'Add image',
    cancel: 'Cancel',
    save: 'Save',
    crop: 'Crop',
    name: 'Title',
    addImg: 'Add image',
  },
  offerNiche: {
    name: 'Title',
    status: 'Status',
    main: 'General',
    nicheOfOffers: 'Niches',
    newNiche: 'Synchronize',
    actions: 'Actions',
    createOfferNiche: 'Create',
    editOfferNiche: 'Edit',
    offer: 'Offer',
    offers: 'Offers',
  },
  order: {
    modalCopyOrders: 'Are you sure you want to create duplicates of the selected applications?',
    deliveryStatus: 'Delivery status',
    cloneOrders: 'Clone orders',
    order_processing_time: 'Order processing time',
    orderSubstatus: 'Substatus',
    callbackAt: 'Next call',
    callback: 'Call back',
    commentToPostman: 'Comment to postman',
    sync: 'Synchronization',
    orderSync: 'Order synchronization',
    lastStatusDate: 'Status date',
    prepaymentIncluded: 'Prepayment included',
    sendSms: 'Send SMS',
    warehouse: 'Warehouse',
    historyDate: 'History date',
    statusAndSub: 'Status/substatus',
    callbackDateTime: 'Callback date & time',
    additionalProducts: 'Additional products',
    unsavedChanges: 'Form has unsaved changes',
    downloadInvoice: 'Download PDF-invoice',
    downloadInvoicesBtn: 'Export invoices',
    downloadInvoicesZip: 'Download invoice in ZIP',
    downloadInvoicesPdf: 'Download invoices in PDF',
    currentOperatorList: 'The application is currently being processed by',
    currentOperatorConfirm: 'If you continue editing, the data you entered may be lost. Continue editing?',
    invoice: 'Invoice',
    favorite: 'Favorite',
    favorites: 'Favorites',
    isSyncedSuccessfully: 'Synced successfully',
    action: 'Actions',
    cpaId: 'CPA ID',
    name: 'Name',
    sms: 'SMS',
    orders: 'Orders',
    newOrder: 'New order',
    tracker: 'Tracker',
    createOrder: 'Order',
    editOrder: 'Edit order',
    shippingInfo: 'Shipping data',
    dataClient: 'Customer data',
    openingCard: 'Last opened',
    watchNumber: 'Show phone',
    country: 'Country',
    age: 'Age',
    gender: 'Gender',
    birthdayDate: 'Date of birth',
    countries: 'Countries',
    region: 'State',
    province: 'Provincia only JER',
    address: 'Address',
    address1: 'Address 1',
    code: 'UBIGEO',
    deliveryTerm: 'Delivery term',
    regions: 'States',
    district: 'Colonia (Distrito)',
    city: 'City',
    cityName: 'City',
    cityDep: 'City (Depertamento)',
    street: 'Street',
    phone: 'Phone number',
    createdAt: 'Created',
    lastSet: 'Last dialing',
    lastCall: 'Last call',
    date: 'Date',
    productsInOrder: 'Order items',
    countryOrLanguage: 'Country/language',
    legalPerson: 'Company',
    trackingCode: 'Tracking',
    telephonyStatus: 'Dialer status',
    statusCall: 'Call status',
    calls: 'Calls',
    comments: 'Comment',
    groups: 'Groups',
    reason: 'Reason',
    noProductsByType: 'no products with selected type',
    startDispute: 'Start dispute',
    disputeStarted: 'Dispute opened',
    courierComment: 'Courier comment',
    postmanComment: 'Comment to the postman',
    sendDate: 'Send date',
    house: 'House',
    web: 'Web',
    webs: 'Webs',
    kln: 'QA assessment',
    block: 'Block',
    client: 'Customer',
    other: 'Other',
    delivery: 'Delivery',
    orderPrice: 'Order value',
    productCount: 'qty',
    orderCar: 'Order items',
    orderTags: 'Order tags',
    apartment: 'Apartment',
    postalCode: 'Postal code/ZIP',
    firstCallAt: 'First call',
    lastCallAt: 'Last call',
    durationCall: 'Call duration',
    countCall: 'Total calls',
    callAuto: 'Autodialing call',
    callManual: 'Manual call',
    recordCall: 'Call recording',
    deliveryType: 'Delivery type',
    deliveryTypes: 'Delivery types',
    prepayment: 'Prepayment',
    orderOperator: 'Order operator',
    paymentByTerminal: 'Card payment available',
    suspiciousClientTitle: 'This client already has {amount} refusal',
    suspiciousClientTitleMultiple: 'This client already has {amount} refusals',
    suspiciousClientTitleMultiple5: 'This client already has {amount} refusals',
    suspiciousClientSubtitle: 'Please note your order history before proceeding.',
    deliveryService: 'Delivery service',
    deliveryServices: 'Delivery services',
    deliveryPoint: 'Delivery point',
    deliveryDate: 'Delivery date',
    shippedStatusDate: 'Shipped status date',
    deliveryTime: 'Delivery time',
    deliveryTimeFrom: 'Delivery time from',
    deliveryTimeTo: 'Delivery time to',
    deliveryInterval: 'Delivery interval',
    postalAddress: 'Postal office',
    deliveryIncluded: 'Delivery included',
    firstName: 'Name',
    lastName: 'Surname',
    patronymic: 'Patronymic',
    status: 'Status',
    statuses: 'Statuses',
    script: 'Script',
    products: 'Products',
    product: 'Product',
    history: 'History',
    questionnaires: 'Surveys',
    comment: 'Comments',
    dateTimeCreated: 'Created at',
    sourceOrder: 'Order source',
    regionOfClient: 'State',
    responsibleOperator: 'Responsible operator',
    autodetectPhone: 'Autodetected state/district',
    autoTimeClient: 'Autodetected time',
    carrierId: 'Carrier ID',
    tags: 'Tags',
    userGroups: 'User groups',
    offer: 'Offer',
    offers: 'Offers',
    offerGroups: 'Offer groups',
    offerNiches: 'Niches',
    niches: 'Niches',
    rejectReason: 'Status reason',
    rejectReasons: 'Status reasons',
    rejectReasonCanceledRC: 'Canceled RC',
    callStatus: 'Call status',
    callStatuses: 'Call statuses',
    activity: 'State',
    landing: 'Landing',
    landings: 'Landings',
    operator: 'Operator',
    operatorFirstApprove: 'First Approved operator',
    operators: 'Operators',
    number: 'Phone number',
    phoneList: 'Phones list',
    ordersByNumber: 'Related orders',
    noOrdersByNumber: 'No orders',
    typeOrOperator: 'Type/operator',
    checkPrice: 'Order total',
    currencyName: 'Currency',
    type: 'Type',
    general: 'General',
    additional: 'Extras',
    cpaData: 'CPA network data',
    cpaDataTooltip: {
      cpaId: 'CPA order ID',
      webId: 'Web ID',
      leadPrice: 'Advertisement lead price',
    },
    tracking: 'Tracking',
    createTicket: 'Create ticket',
    createReclamation: 'Create ticket',
    createTicketByClient: 'Create ticket',
    historyOfOrder: 'Order history',
    noLogOrders: 'No history',
    noSmsFound: 'No SMS',
    callbackReason: 'Callback reason',
    statusReason: 'Status reason',
    was: 'Before',
    became: 'After',
    system: 'System',
    orderList: 'Order items',
    orderBundle: 'Products in set',
    sum: 'Total',
    allSum: 'Order total',
    productsPrice: 'Products price',
    deliveryPrice: 'Delivery price',
    orderWillBeDeliveredWithin: 'Order will be delivered in {min}-{max} days',
    male: 'M',
    female: 'F',
    callbackDate: 'Date',
    callbackTime: 'Time',
    listNumbers: 'Phone list',
    callToLead: 'Dialing',
    callInProgress: 'Call in progress',
    makeCall: 'Make a call',
    shouldCall: 'Start dialing?',
    abounentIsNotAvailable: 'Subscriber unavailable',
    abounentIsNotAvailableDescription: 'Subscriber is busy or unavailable...',
    callAgain: 'Dial again',
    attention: 'Warning',
    mediaDeviceError:
      'Give permissions to access your input and output devices in the browser to start calling.',
    mediaConnectStep1: 'Click an icon to the left from URL line.',
    readyStatusError:
      'To select "Ready" status, you must be connected to a dialer. Contact your manager or tech support team.',
    mediaConnectStep2: 'Click В«Reset permissionsВ»',
    mediaConnectStep3: 'Reload page',
    noOktellConnection: 'No connection to dialer server',
    oktellConnectionError: 'Connection error',
    oktellOffline: 'Offline',
    oktellOnline: 'Online',
    sendMessage: 'Send SMS',
    actions: {
      actions: 'Actions',
      changeStatus: 'Change status',
      substatus: 'Substatus',
      addTags: 'Add tags',
      removeTags: 'Remove tags',
      removeProduct: 'Remove product from order',
      statusReason: 'Status reason',
      callback: 'Schedule callback',
      approveByName: 'Approved by',
    },
    sourceTitle: {
      cpa: 'Hot sales',
      manual: 'Manually',
      importFile: 'File import',
      campaign: 'Cold sales',
    },
    sourceCPA: 'Hot sales',
    sourceAddProject: 'Cold sales',
    sourceIncome: 'Incoming',
    sourceUser: 'Create manually',
    actionsSuccess: 'Actions applied to selected orders',
    ticketsCreated: '{count} ticket(s) created',
    forPackaging: 'To packing',
    filterByDate: 'Date filter',
    hoursFromApprove: 'More than N hours',
    warehouses: 'Shipping warehouses',
    dateFilterType: {
      createdAt: 'By Created',
      approvedAt: 'By Approved',
      shippedAt: 'By Shipped',
      paidAt: 'By Paid',
    },
    firstApprovedAt: 'Approved date',
    shippedDate: 'Shipped date',
    packerTask: 'Packing list',
    autoComment: 'Autocomment',
    callbackListEmpty: 'No scheduled callbacks',
    statusDeliveryService: 'Delivery service status',
    synchronizationDate: 'Synchronization date',
    isSynced: 'Is orders synchronized',
    synchronizeIncorrectly: 'The price is equal',
    commentAnother: 'Comment',
    ageRules: 'Max age 120',
    helpTeamLead: 'Assistance request sent',
    deliveryServiceName: 'Delivery service',
    metashipStatus: 'Delivery service status',
    metashipStatusDate: 'Delivery service status date',
    tooltipVIcon: 'Script and offer settings do not fully correspond',
    attribute: 'Attributes',
    attributes: {
      hot: 'Hot',
      cold: 'Cold',
      no_ds: 'No DS',
      trash_geo: 'Trash geo',
      cancel: 'Cancel 72',
      approve_after_cancel: 'Approve after cancel',
      no_manager: 'No manager',
      call_queue_error: 'Call queue error',
    },
  },
  rejectReason: {
    rejectReasons: 'Status reasons',
    newRejectReason: 'New reason',
    name: 'Title',
    actions: 'Actions',
    createRejectReason: 'Cancelation reason',
    editRejectReason: 'Cancelation reason',
    editStatusReason: 'Status reason',
    main: 'General',
    trashMain: 'Trash',
    toOperator: 'Stated by operator',
    toOperatorTableHead: 'Stated by operator',
    explanation: 'Description',
    description: 'Description',
    textForCpa: 'Affiliate response',
    applicableStatuses: 'Applicable to statuses',
    correspondsSystem: 'Corresponds to system reason',
    correspondsSystemReason: 'Corresponds to system reason "Invalid"',
    incorrect: 'Invalid',
    canceled: 'Canceled',
    trash: 'Trash',
    trashForm: 'Trash',
    callback: 'Callback',
    processing: 'Processing',
    in_blacklist: 'Phone in the black list',
    invalid_phone: 'Invalid phone',
    stop_word: 'Stop words',
    duplicate: 'Duplicate',
    reason_wrong_geo: 'Wrong geo',
    reason_test_lead: 'Test lead',
    reason_dublicate_order: 'Dublicate order',
    reason_could_not_get_through: 'Could not get through',
    reason_possibly_froud: 'Possibly froud',
    reason_wrong_ads: 'Wrong  ads',
    reason_did_not_order: 'Did not order',
    reason_incorrect_phone: 'Incorrect phone',
    reason_wrong_targeting: 'Wrong targeting',
    reason_consultation: 'Consultation',
    reason_expensive: 'Expensive',
    reason_changed_his_mind: 'Changed his mind',
  },
  productGift: {
    createProductGift: 'Catalog',
    editProductGift: 'Catalog',
    productGifts: 'Catalogs',
    active: 'State',
    newProductGift: 'New catalog',
    catalog: 'Catalog',
    actions: 'Actions',
    main: 'General',
    name: 'Title',
    country: 'Country',
    product: 'Product',
    settings: 'Settings',
    offers: 'Offers',
    storages: 'Warehouses',
    offerGroups: 'Offer groups',
    offerExclusion: 'Excluded offers',
    addToMail: 'Postal delivery orders included',
    mailOrders: 'Postal delivery orders',
    creator: 'Created by',
    creationDate: 'Created',
    activeProductGift: 'Active catalogs',
    productGiftInset: 'Enclose',
    productGiftNotInset: 'Do not enclose',
    productGiftAll: 'All',
  },
  phoneCodes: {
    codes: 'Phone codes',
    newCode: 'New code',
    code: 'Code',
    minNumber: 'Min number',
    maxNumber: 'Max number',
    timezone: 'Timezone',
    time: 'Time',
    countryCode: 'Country code',
    regionCode: 'State code',
    region: 'District',
    operator: 'Mobile network operator',
    actions: 'Actions',
    phoneCode: 'Phone code',
    name: 'Title',
    internalCode: 'Local code',
    timezoneName: 'Timezone',
    regionDistrict: 'State/district',
    country: 'Country',
    editPhoneCodes: 'Phone code',
  },
  city: {
    city: 'Cities',
    newCity: 'New city',
    createCity: 'City',
    main: 'General',
    create: 'City',
    country: 'Country',
    region: 'State',
    district: 'District',
    deliveryService: 'Delivery service',
    deliveryType: 'Delivery type',
    serviceName: 'Warehouse',
    terminal: 'Card payment',
    locationId: 'Location ID',
    actions: 'Actions',
    editCity: 'City',
    deliveryServiceAndStorage: 'Delivery service and warehouse',
    warehouse: 'Warehouse',
    refId: 'ref_id',
    availableTerminal: 'Card payment',
    terminalPresent: 'Card payments available',
    terminalAbsent: 'No card payments',
    terminalAll: 'All',
    loadFromFile: 'Upload from file',
    actionsModal: {
      actionType: 'Action',
      addDeliveryService: 'Add Delivery service',
      removeDeliveryService: 'Remove Delivery service',
      deliveryServices: 'Delivery services',
      warehouses: 'Delivery service warehouses',
    },
    attention: 'Warning',
    actionsError: 'One city and delivery service pair can have only one shipping warehouse',
    actionsSuccess: 'Actions applied to selected cities',
    postEnabledTill: 'Post enabled till',
    modalDesc: 'Entity with the same title already exists.',
    cancel: 'Cancel',
    continue: 'Proceed',
    filter: {
      terminalExist: 'Terminal exists',
      noTerminal: 'No terminal',
      all: 'All',
    },
  },
  salesScript: {
    scripts: 'Scripts',
    newScript: 'New script',
    name: 'Title',
    general: 'General',
    script: 'Script',
    preview: 'Preview',
    priceFormation: 'Pricing model',
    exercisesCount: 'Items quantity in treatment course',
    coursePrice: 'Treatment course price',
    main: 'General',
    language: 'Language',
    countries: 'Countries',
    offers: 'Offers',
    offer: 'Offer',
    version: 'Version',
    sections: 'Chapters',
    salesType: 'Sales type',
    userGroups: 'Operator groups',
    userGroupsDesc:
      'Select operator groups for script testing. Make sure that selected operators added to offer dialer settings.',
    operatorsDesc:
      'Select operators for script testing. Make sure that selected operators added to offer dialer settings.',
    salesModel: 'Sales model',
    users: 'Operators',
    actions: 'Actions',
    createScript: 'Script',
    editScript: 'Script',
    sectionName: 'Title',
    paragraphName: 'Title',
    plainText: 'Plain text',
    addParagraph: 'Add paragraph',
    addSelection: 'Add chapter',
    paragraphInSection: 'Paragraph in chapter',
    textOfParagraph: 'Paragraph content',
    moves: 'Transitions',
    addGroup: 'Add group',
    create: 'Create',
    template: 'Template',
    stylesTitle: 'Styles',
    clearStyles: 'Clear styles',
    colorText: 'Text color',
    macroses: 'Macroses',
    chooseStyleOfText: 'Select text style',
    hideFromSection: 'Hide from contents',
    createFromTemplate: 'Create from template',
    saveSectionLikeTemplate: 'Save chapter as template',
    invalidFields: 'Empty required fields in "General" tab',
    invalidFieldsSets: 'Empty required fields in "Product sets" tab',
    modalDescRemovingParagraph:
      'There are active transitions to the paragraph from other paragraphs. Are you sure to delete it?',
    saveSectionAsTemplate: 'Save chapter as a template',
    active: 'State',
    requestHelpTeamLeader: 'Request assistance request',
    templateFromSection: {
      name: 'Chapter template title',
      description: 'Description',
    },
    transition: {
      transitionGroupName: 'Transition group title',
      nameOfBtn: 'Button title',
      paragraphAfterMoving: 'Paragraph after transition',
      colorBtn: 'Color button',
      type: {
        info: 'info',
        success: 'success',
        warning: 'warning',
        danger: 'danger',
      },
    },
    styles: {
      name: 'Title',
      tooltip: 'Tooltip',
      editableStyles: 'Editable styles',
      exampleText: 'This is a template to show the applied text styles...',
    },
    macros: {
      name: 'Title',
      macroses: 'Macroses',
      systems: 'System',
      common: 'General',
      modalDescRemoving: 'Deactivated macros will still be shown in scripts, where it was used.',
    },
    macrosStatus: {
      main: 'Main',
      substitute: 'Substitute',
      manually: 'Alternative',
      additional_cross: 'Additional in set',
      aware_cross: 'Additional',
      another: 'Other',
    },
    offerList: {
      offerList: 'Offers list',
      groups: 'Offer group',
      niches: 'Niches',
      show: 'Show',
      offer: 'Offer',
      niche: 'Niche',
    },
  },
  financeSettings: {
    finance: 'Financial settings',
    addFinance: 'Financial settings',
    editFinance: 'Financial settings',
    main: 'General',
    country: 'Country',
    region: 'State',
    currency: 'Currency',
    timezone: 'Default timezone',
    postalService: 'Default delivery service',
    timezoneForm: 'Timezone',
    deliveryCost: 'Delivery cost',
    actions: 'Actions',
    length: '10',
  },
  orderTag: {
    name: 'Title',
    main: 'General',
    actions: 'Actions',
    createOrderTag: 'Tags',
    editOrderTag: 'Tags',
    newTag: 'New tag',
    tags: 'Tags',
  },
  salesScriptTemplate: {
    scriptSectionTemplates: 'Script chapter templates',
    newScriptTemplate: 'New template',
    name: 'Title',
    actions: 'Actions',
    createScriptTemplate: 'Template',
    editScriptTemplate: 'Template',
    sections: 'Chapters',
    paragraphsInSection: 'Paragraphs in chapter',
    description: 'Description',
    textOfParagraph: 'Paragraph content',
    moves: 'Transitions',
    addGroup: 'Add group',
    preview: 'Preview',
    active: 'State',
  },
  landing: {
    landing: 'Affiliate websites',
    addLanding: 'Add affiliate website',
    removeLanding: 'Add affiliate website',
    main: 'General',
    name: 'Title',
    url: 'URL',
    offer: 'Offer',
    landingEdit: 'Affiliate website',
    landingCreate: 'Affiliate website',
    forToday: 'Today',
    forWeek: 'By week',
    forMonth: 'By month',
    actions: 'Actions',
  },
  deliveryPoint: {
    created: 'Created',
    createdAnother: 'Created',
    lastEdited: 'Last edited',
    lastEditedAnother: 'Last edited',
    deliveryPoints: 'Delivery points',
    deliveryPoint: 'Delivery point',
    deliveryPointEdit: 'Delivery point',
    addDeliveryPoint: 'Add',
    name: 'Title',
    deliveryType: 'Delivery type',
    deliveryService: 'Delivery service',
    terminal: 'Card payment',
    terminalPayment: 'Card payment',
    city: 'City',
    region: 'State',
    district: 'District',
    country: 'Country',
    active: 'State',
    locationId: 'Location ID',
    actions: 'Actions',
    postalCode: 'Postal code',
    main: 'General',
    keyIntegration: 'Integration key',
    deliveryServiceAndStorage: 'Delivery service and warehouse',
    service: 'Service',
    storage: 'Warehouse',
    loadFromFile: 'Upload from file',
    minDay: 'Min days',
    maxDay: 'Max days',
    address: 'Address',
    phoneNumber: 'Phone number',
    comment: 'Comment',
    workTime: 'Working hours',
    status: 'Status',
    activePoint: 'Activated',
    deactivatedPoint: 'Deactivated',
    paymentByTerminal: 'Card payment',
    addTerminal: 'Available',
    removeTerminal: 'Not available',
    actionsSuccess: 'Actions applied to selected delivery points',
    activateDescription: 'Confirm activation of selected delivery point.',
    deactivateDescription: 'Confirm deactivation of selected delivery point.',
    activateSuccess: 'Activated delivery points: {count}',
    deactivateSuccess: 'Deactivated delivery points: {count} ',
    updateData: 'Update delivery point data',
  },
  orderImport: {
    loadFromFile: 'Upload from file',
    downloadTemplateYouCanHere: 'Download template here',
    noSyncedOrders: 'No synced orders: {orders}',
    createRelatedOrders: 'Create requests for a related offer',
    importWarehouseExample: 'Warehouse import template.xlsx',
    addFile: 'Upload file',
    preview: 'Preview',
    importData: 'Import data',
    status: 'Status',
    offer: 'Offer',
    webs: 'Webs',
    country: 'Country',
    language: 'Language',
    tags: 'Tags',
    correctRows: 'Rows without errors',
    rowsWithErrors: 'Rows with errors',
    modalImportDesc: 'Only rows with no errors will be uploaded.<br/>Do you want to continue?',
    field: {
      age: 'Age',
      apartment: 'Apartment',
      birthdayDate: 'Date of birth',
      city: 'City',
      errors: 'Info',
      gender: 'Gender',
      house: 'House',
      lastName: 'Surname',
      offer: 'Offer',
      name: 'Name',
      orderPrice: 'Order total',
      patronymic: 'Patronymic',
      phone: 'Phone number',
      postalCode: 'Postal code',
      product: 'Product',
      street: 'Street',
      trackingCode: 'Tracking number',
    },
  },
  deliveryInterval: {
    deliveryIntervals: 'Delivery intervals',
    deliveryInterval: 'Delivery interval',
    main: 'General',
    deliveryService: 'Delivery service',
    city: 'City',
    deliveryPoint: 'Delivery point',
    from: 'Interval from',
    to: 'Interval to',
    boundaryTime: 'Time boundary',
    deliveryPeriodMax: 'Max days',
    deliveryPeriodMin: 'Min days',
    deliveryPeriodMaxShort: 'Max days',
    deliveryPeriodMinShort: 'Min days',
    intervals: 'Intervals',
    availableDeliveryDays: 'Available delivery days',
    nameDeliveryService: 'Delivery service title',
  },
  stopWords: {
    stopWords: 'Stop words',
    main: 'General',
    description: 'Description',
    alertDescription: 'List all stop words or phrases in text field separated by commas.',
  },
  deliveryWeekend: {
    deliveryWeekends: 'Holidays',
    deliveryWeekend: 'Holiday',
    addDeliveryWeekend: 'Add holiday',
    deliveryWeekendEdit: 'Holiday',
    name: 'Title',
    actions: 'Actions',
    main: 'General',
    country: 'Country',
    region: 'State',
    district: 'District',
    city: 'City',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    deliveryServices: 'Delivery service',
    active: 'State',
  },
  deliveryType: {
    courier: 'Courier',
    courier_interval: 'Courier (interval)',
    post: 'Post',
    pvz: 'Delivery point',
    fulfillment: 'Fulfillment',
  },
  deliveryServices: {
    nameColumn: 'Title',
    countriesColumn: 'Countries',
    countryColumn: 'Country',
    regionColumn: 'State',
    cityColumn: 'City',
    serviceNameColumn: 'Warehouse',
    deliveryService: 'Add warehouse',
    createDeliveryServicePageTitle: 'Delivery service',
    editDeliveryServicePageTitle: 'Delivery service',
    cityFilterTitle: 'City',
    warehouseFilterTitle: 'Warehouse',
    createEditFormTitle: 'General',
    idOneC: 'ID 1C',
    sync: 'Synchronize',
    sync1C: 'Synchronize',
    tooltipMessage: 'Delivery points update will start after clicking "Save"',
    syncProcessing: 'Synchronization in progress',
    syncSuccess: 'Synchronization finished successfully',
    syncFail:
      'Delivery point synchronization error. \n' +
      'Make sure that selected shop works with DS, \n' +
      'check log or contact tech support team.',
    name: 'Title',
    deliveryType: 'Delivery type',
    type: 'Delivery type',
    description: 'Description',
    relatedCities: 'Cities',
    dayForPayment: 'Days for payment',
    mainCountry: 'Main delivery country',
    updateDataMetaship: 'Update delivery points?',
    yes: 'Yes',
    no: 'No',
    code: 'DS code',
    metashipDSCode: 'Delivery service ID',
    syncWithMetaship: 'Sync',
    idMetaship: 'Shop ID in delivery service',
    tooltip: 'Delivery points update will start after clicking "Save"',
  },
  campaign: {
    title: 'Call campaign',
    titleItem: 'Campaign',
    newCampaign: 'Add',
    addManual: 'Select orders manually',
    filter: {
      offers: 'Offers',
      salesType: 'Sales type',
      activity: 'State',
      countries: 'Countries',
    },
    table: {
      id: 'ID',
      name: 'Title',
      countryLang: 'Country',
      typeSell: 'Sales type',
      date: 'Created',
      nameCreator: 'Created by',
      status: 'Campaign status',
      actions: 'Actions',
    },
    form: {
      title: 'Campaign settings',
      titleEdit: 'General',
      titleEditList: 'Dialing queue',
      name: 'Title',
      salesType: 'Sales type',
      country: 'Country',
      language: 'Language',
      questionnaire: 'Survey',
      activity: 'Campaign status',
      offers: 'Offers',
      products: 'Products',
      startDate: 'Status date from',
      endDate: 'Status date to',
      timeAfter: 'Time from status',
      timeAfterSkip: 'Skip if later than',
      dayDelay: 'Days for first base product item',
      dayDelayNext: 'Days for every next product item',
      productQuantity: 'Product quantity',
      statuses: 'Statuses',
      absentOffer: 'Missing appropriate offer:',
      absentScript: 'Missing appropriate script',
      scripts: 'Offers',
    },
    queueList: {
      id: 'Order No',
      status: 'Call status',
      create: 'Created order',
      date: 'Date/time',
    },
    manual: {
      title: 'Manual campaign',
      form: {
        title: 'General',
        name: 'Title',
        offer: 'Offer',
        dateStart: 'Campaign start date',
        activity: 'Campaign status',
      },
      filterOrder: {
        title: 'Filter orders',
        salesType: 'Sales type',
        niche: 'Niche',
        tags: 'Tags',
        country: 'Country',
        offers: 'Offers',
        language: 'Language',
        offerGroups: 'Offer groups',
        status: 'Status',
        products: 'Products',
      },
      tableOrder: {
        id: 'Order в„–',
        niche: 'Niche',
        offer: 'Offer',
        operator: 'Operator',
        operatorGroup: 'Operator group',
      },
      formManualCampaignErrorTitle: 'Warning',
      formManualCampaignError: 'Add one or more orders to the campaign',
    },
  },
  productSets: {
    productSets: 'Product sets',
    createProductSets: 'Product set',
    editProductSets: 'Product set',
    newProductSets: 'Add',
    placeholderInput: 'Title',
    placeholderAlternativeTitle: 'Alternative title',
    nameForScripts: 'Title for scripts',
  },
  blackPhone: {
    blackPhone: 'Blacklist',
    newBlackPhone: 'Add',
    dateOfCreation: 'Created',
    phone: 'Phone number',
    author: 'Created by',
    addToBlacklist: 'Add to blacklist',
    phoneNumber: 'Phone number',
  },
  groupTypes: {
    groupTypes: 'User group types',
    newGroupTypes: 'Add',
    name: 'Title',
    groups: 'Groups',
    groupTypesAdd: 'Add group type',
    groupTypesCancel: 'Add',
  },
  tickets: {
    goToOrder: 'To the order',
    creatBy: 'Created by:',
    createData: 'Created:',
    tickets: 'Tickets',
    myTickets: 'My tickets',
    ticket: 'Ticket',
    created: 'Created',
    whoCreate: 'Created by',
    ticketCreatedBy: 'Created by',
    type: 'Type',
    application: 'Order',
    priority: 'Priority',
    status: 'Status',
    group: 'Group',
    accountable: 'Assignee',
    accountableGroupUsers: 'Assigned user group',
    accountableGroup: 'Assigned group',
    accountableUser: 'Assignee',
    main: 'General',
    ticketType: 'Ticket type',
    deadLine: 'Deadline date',
    deadline: 'Deadline',
    createdAt: 'Created',
    timer: 'Timer',
    executor: 'Assignee',
    timeLeft: 'Time left: less than ',
    lessThan: 'less than ',
    errorCalc: 'Missing order status. Deadline is set to 0.',
    executionPeriod: 'Deadline (date)',
    executionPeriodHours: 'Deadline (hours)',
    problemDescription: 'Description',
    commentAccountableUser: 'Assignee comment',
    dateCreation: 'Created',
    titleCreate: 'Create tickets',
    operatorAbbreviation: 'first approve operator',
    noOperator: 'no operator',
    attributes: {
      title: 'Attributes',
      no_user: 'No assignee',
      no_group: 'No assigned group',
      no_deadline: 'No deadline',
      overdue: 'Expired',
    },
    modal: {
      ticketType: 'Ticket types',
      titleDeadline: 'Ticket deadline will be set automatically based on ticket type.',
      titleExecutor: 'Ticket assignee will be set automatically based on ticket type.',
      title: 'Create tickets',
      titleGeneral: 'Deadline and assignee will be set automatically based on ticket type.',
    },
  },
  ticketType: {
    reclamation: 'Reclamation',
    client_request: 'Client request',
  },
  ticketPriority: {
    high: 'High',
    medium: 'Medium',
    low: 'Low',
  },
  questionnaireKnl: {
    title: 'QA assessment',
    crete: 'Add',
    shortName: 'Short title',
    addItem: 'Add question',
    itemName: 'Question title',
    name: 'Title',
    score: 'Points',
    evaluationOptions: 'Options',
    actions: 'Actions',
    create: 'Create',
    sections: 'Chapters',
    evaluationPoint: 'Assessment questions',
    settingsItems: 'Assessment questions settings',
    fullName: 'Full title',
    numberOfSection: 'Chapter number',
    evaluatingNameByValue: {
      yes: 'Yes',
      no: 'No',
      partial: 'Partially',
      dont_consider: 'Disregard',
    },
  },
  warehouseStock: {
    table: {
      outerId: 'Outer ID',
      warehouse: 'Warehouse',
      name: 'Name',
      country: 'Country',
      type: 'Product type',
      amount: 'Amount',
    },
  },
  deliveryWarehouse: {
    warehouses: 'Warehouses',
    warehouse: 'Warehouse',
    name: 'Title',
    location: 'Location',
    regionDelivery: 'Delivery to',
    deliveryService: 'Delivery services',
    remainingGoodsExport: 'Export of remaining stock',
    actions: 'Actions',
    add: 'Add',
    main: 'General',
    sync: 'Synchronize',
    syncWith1C: 'Synchronize',
    idOneC: 'Outer ID',
    serviceName: 'Warehouse',
    comment: 'Comment',
    countryLocation: 'Country',
    regionLocation: 'State',
    manager: 'Manager',
    relatedDeliveryServices: 'Available delivery services',
    city: 'City',
    region: 'State',
    country: 'Country',
    modalTitle: 'Warning',
    modalContent: 'ID not found',
    area: 'States',
    amount: 'Qty',
    percent: '%',
    resultCol: 'Total',
    postEnableHour: 'Allow postal delivery for N hours',
    table: {
      name: 'Name',
      barcode: 'Barcode',
      id: 'ID',
      inStock: 'In stock',
      warehouse: 'Warehouse',
      errors: 'Errors',
    },
  },
  orderFraud: {
    orderFraud: 'Invalid statistics ',
    applicationSource: 'Order source',
    totalApplications: 'Total orders',
    incorrect: 'Invalid',
    percentIncorrect: '% Invalid',
  },
  orderSuspicious: {
    incorrect: 'Invalid',
    forProcessing: 'To processing',
    changeStatus: {
      title: 'Status change',
      description: 'Are you sure to change the status for selected orders?',
    },
  },
  CurrencyConversion: {
    main: 'General',
    title: 'Currency rates',
    course: 'Currency rates',
    updated: 'Updated',
    rub: 'EUR',
    percentIncorrect: '% Invalid',
    at: 'at',
    noCurrenciesData: 'Select currency to receive rates',
    converterLink: 'apilayer.com',
    loading: 'Loading...',
    baseCurrency: 'System currency',
    getCurrencies: 'Get currency rates',
    changesSaved: 'Currency changes saved',
    attention: 'Warning',
    willRecount: 'All report will be recalculated if you change the system currency',
    mayErrors: 'Errors possible due to missing historical currency rates in system.',
    continue: 'Proceed',
  },
  exportReports: {
    general: 'General',
    callCenter: 'Call center',
    logistics: 'Logistics',
    finance: 'Finances',
    redemptionByDay: 'Delivered by days',
    reportOrdersCourierDelivery: 'Courier delivery orders',
    hourlyOrdersToday: 'Orders per hour today',
    ordersHourSevenDays: 'Orders per hour by 7 days',
    notificationReportInactive: 'Notifications report (inactive)',
    reportRlabNotifications: 'Notifications report',
    reportCanceledConfirmed: 'Canceled/Approved report',
    repeatCampaignReport: 'Repeat sales report',
    reportSettings: 'Report settings',
    download: 'Download',
    selectReport: 'Select report first',
    applicationsSpecifiedPeriod: 'Orders for specified period',
    applicationsSpecifiedPeriodLogistic: 'Orders for specified period (products list)',
    period: 'Period',
    writeOffProducts: 'Write off products',
    selectFields: 'Select columns',
    fields: {
      offer: 'Offer',
      salesModel: 'Sales model',
      niche: 'Niche',
      dateCreate: 'Created date/time',
      dateApprove: 'Approved date/time',
      dateRansom: 'Delivered date/time',
      fio: 'Full name',
      gender: 'Gender',
      age: 'Age',
      birthDate: 'Date of birth',
      firstApproveDate: 'First approve date/time',
      sendDate: 'Send date',
      comment: 'Comment',
      country: 'Country',
      district: 'State',
      city: 'City',
      address: 'Address',
      postalCode: 'Postal code',
      deliveryType: 'Delivery type',
      deliveryService: 'Delivery service',
      availableDeliveryServiceType: 'Available delivery services',
      deliveryPoint: 'Delivery point',
      deliveryDate: 'Delivery date',
      trackNumber: 'Tracking number',
      orderPrice: 'Order total',
      web: 'Web',
      landing: 'Landing',
      salesType: 'Sales type',
      status: 'Status',
      substatus: 'Substatus',
      mainOperator: 'Мain operator',
      productType: 'Product types',
      product: 'Product',
      phone: 'Phone number',
      warehouse: 'Warehouse',
      prepayment: 'Prepayment',
      logs: 'Order log',
      orderId: 'Order ID',
      cpaId: 'CPA ID',
      currency: 'Currency',
      name: 'Name',
      lastName: 'Last name',
      patronymic: 'Patronymic',
      street: 'Street',
      house: 'House',
      apartment: 'Apartment',
      offerId: 'Offer ID',
    },
    selectStatus: 'Statuses',
    salesModel: 'Sales model',
    subStatuses: 'Substatuses',
    deliveryService: 'Delivery service',
    userGroups: 'Operator groups',
    shippedMoreThan: 'Orders shipped more than 20 days',
    shippedMoreThanNoBarcode: 'Orders shipped more than 15 days without tracking',
    approvedMoreThan14DayCourier: 'Approved and not shipped more than 14 days for courier delivery',
    approvedMoreThan10Day: 'Approved and not shipped more than 10 days for non-courier delivery',
    awaitingRansomMoreThan30Day: 'Awaiting postal delivery for more than 30 days',
    awaitingRansomMoreThan15Day: 'Awaiting non-postal delivery for more than 15 days',
    ordersWithoutBarcode: 'Orders with no tracking number',
    deliveryIntervalsForDeliver: 'Delivery intervals',
    deliveryPointChanges: 'Delivery points updates log',
    ordersListWithQuantity: 'CSV__Orders_List_with_quantity',
    reportCod: 'Raport COD',
    productList: 'Product_list',
    summaryPointReport: 'QA scores',
  },
  smsTemplate: {
    title: 'SMS templates',
    titleForm: 'SMS template',
    filter: {
      active: 'State',
    },
    table: {
      name: 'Title',
      createAt: 'Created',
      author: 'Created by',
      actions: 'Actions',
    },
    form: {
      title: 'Template settings',
      name: 'Title',
      text: 'SMS content',
      prev: 'Preview',
      symbols: 'Symbols:',
      sms: 'SMS:',
      info: 'The number of symbols is approximate. The exact number of symbols and SMS quantity you will receive from your SMS provider.',
      isAds: 'Promo',
    },
    macros: {
      fname: {
        label: 'Name',
        text: 'Sam',
      },
      lname: {
        label: 'Surname',
        text: 'Simmons',
      },
      patronymic: {
        label: 'Patronymic',
        text: '',
      },
      fullName: {
        label: 'Full_name',
        text: 'Sam Simmons',
      },
      orderNumber: {
        label: 'Order_No',
        text: '#1123',
      },
      phone: {
        label: 'Phone',
        text: '+12345678900',
      },
      price: {
        label: 'Price',
        text: '2500',
      },
      deliveryAddress: {
        label: 'Delivery_point_address',
        text: 'Kyiv, Vasylkivska street, 123',
      },
      tracker: {
        label: 'Tracker',
        text: 'X23ert$wesa',
      },
      mainProduct: {
        label: 'Main_product',
        text: 'Diaxil',
      },
    },
  },
  workTimeLogType: {
    unavailable: 'Unavailable',
    wait: 'Awaiting',
    offline: 'Offline',
    notAvailable: 'Unavailable',
    autoCall: 'Auto dialing',
    manualCall: 'Manual calls',
    ready: 'Ready',
    orderProcessing: 'Order processing',
    personalBreak: 'Personal break',
    education: 'Product training',
    kln: 'QA training',
    consultation: 'Consultation',
    technicalBreak: 'Technical break',
    pause: 'Not ready',
    done: 'Ready',
    modalInfo:
      'You cannot go on a break now. There are too many operators on personal break right now.',
    infoErr:
      'You cannot go on a break now. There are too many operators on personal break right now.',
    technicalBreakShort: 'Technical break',
    personalBreakShort: 'Personal break',
  },
  workTime: {
    workTime: 'Work time and efficiency',
    operator: 'Operator',
    workTimeTable: 'Online time',
    totalApproves: 'Total Approved',
    totalOrder: 'Total',
    totalOrderTime: 'Approval processing',
    hotApproves: 'Hot Approved',
    coldApproves: 'Cold Approved',
    efficiency: 'Efficiency',

    timeLimit: 'You have already used your break time limit',
    movedWorkTime: 'You are in the "Personal Break" status',
    availableBreakTime: 'Available break time',
    imHere: 'I am here',
    modalText:
      'You are inactive for more than {minutes} minutes. Your status will be changed to "Unavailable" in 20 seconds.',
    modalInactiveText: 'Your status has been changed to "Unavailable". To change to "Ready" status, click the "I\'m here" button or change it manually.',
    modalTitle: 'Warning',
    filterTitle: 'View format',
    filterType: 'View format',
    reflectionSumNumber: 'Total value',
    reflectionAvg: 'Average value per day',
    done: 'Done',
    applicationProcessing: 'Order processing',
  },
  limitTime: {
    limitTimeTitle: 'Operator time limit',
    limitTime: 'Time limit',
    limitMinutes: 'Minutes limit',
    limitTimePlaceholder: 'Allowed break time, min',
    limitTimeInaction: 'Allowed inaction time, min',
  },
  district: {
    districts: 'Districts',
    district: 'District',
    main: 'General',
    name: 'Title',
    region: 'State',
    country: 'Country',
    actions: 'Actions',
    addDistrict: 'Add',
    loadFromFile: 'Upload from file',
  },
  orderTrash: {
    PotentialTrash: 'Trash orders',
    date: 'Date',
    lastCall: 'Last dialing',
    offers: 'Offers',
    client: 'Customer',
    countryLanguage: 'Country/language',
    showNumber: 'Show phone',
    status: 'Status',
    processedBy: 'Processed by',
    operator: 'Operator',
    groups: 'Groups',
    comments: 'Comment',
    trash: 'Trash',
    dispute: 'Disputed',
    trashChecked: 'Processed trash',
    trashRaw: 'New trash',
  },
  ticketStatus: {
    new: 'New',
    in_progress: 'Processing',
    fixed: 'Closed',
    closed: 'Closed',
  },
  logAuth: {
    logAuth: 'Authentication log',
    nameOrEmail: 'Full name',
    eventName: 'Event title',
    eventTime: 'Event time',
    IpAddress: 'IP address',
    country: 'Country',
    region: 'State',
    browser: 'Browser',
    login_success: 'Login',
    login_fail: 'Wrong password',
    login_logout: 'Logout',
    watch_number: 'Phone view',
    open_order: 'Opened order',
  },
  logOrder: {
    logOrder: 'Order logs',
    date: 'Date',
    user: 'User',
    open: 'Opened',
    close: 'Closed',
    duration: 'Duration',
    offer: 'Offer',
    phoneNumber: 'Phone number',
    status: 'Status',
    reason: 'Reason',
    sum: 'Value',
    locality: 'City',
    deliveryType: 'Delivery type',
    showNumber: 'Show phone',
  },
  userPenalty: {
    usersPenalties: 'Operator fines',
    userPenalty: 'Fine',
    penaltyAmountRu: 'Fine',
    penaltyAmountHrn: 'Fine',
    penaltyAmountLe: 'Fine',
    penaltyRu: 'Amount',
    penaltyUa: 'Fine',
    penaltyLe: 'Fine',
    description: 'Description',
    name: 'Title',
    main: 'General',
    actions: 'Actions',
  },
  region: {
    regions: 'States',
    region: 'State',
    main: 'General',
    country: 'Country',
    code: 'Code',
    timezone: 'Timezone',
    name: 'Title',
    actions: 'Actions',
  },
  additionalOffers: {
    title: 'Related offers',
    currentOffer: 'Current offer',
    salesType: {
      repeated: 'Repeat sales',
      Canceled: 'Canceled',
      no_ransom: 'Return',
      certificate: 'Certificates',
    },
  },
  questionnaires: {
    questionnaires: 'Surveys',
    name: 'Title',
    actions: 'Actions',
    createQuestionnaires: 'Survey',
    general: 'General',
    questions: 'Questions',
    main: 'General',
    country: 'Country',
    salesType: 'Sales type',
    language: 'Language',
    subtitle: 'To open next tab, fill in the form and save the page.',
    dependent: 'Dependent',
    addAnswer: 'Add answer',
    deleteAnswer: 'Delete question',
    answer: 'Question',
    answerName: 'Title',
    add: 'Add',
    openAnswer: 'Answer explanation needed',
    filterCountries: 'Countries',
    filterOffers: 'Offers',
    filterGroupOffers: 'Offer groups',
    filterTags: 'Tags',
    filterOperators: 'Operators',
    filterOperatorsGroup: 'Operator groups',
    filterSalesType: 'Sales type',
    hotOperator: 'Hot order operator',
    questionnaireOperator: 'Survey operator',
    offer: 'Offer',
    result: 'Result',
    operator: 'Survey operator',
    group: 'Groups',
    questionnaire: 'Survey',
    numberOfRecords: 'Number of records',
    dependOnAnswer: 'on answer',
    dependOnAnswerQuestion: 'to question',
    applicationForm: 'Order survey',
    dateHot: 'Hot order date',
    hotGroupOperator: 'Hot order operator group',
    date: 'Survey date',
    inputQuestion: 'Enter question',
    errorMessage: 'Question must have at least one answer option',
    duplicateHeader: 'Enter title',
    operatorGroups: 'Operator groups',
  },
  organization: {
    name: 'Title',
    activity: 'State',
    syncWith1C: 'Synchronization',
  },
  operatorGrade: {
    title: 'Operator grades',
    saleType: {
      hot: 'Hot',
      repeated: 'Repeat sales',
    },
    operator: 'Operator',
    saleTypeTitle: 'Sales type',
    workStartDate: 'Employment time (months)',
    groups: 'Group',
    approvePercentOvp: '% Approved',
    ransomPercentOvp: '% Delivered',
    approveCheckOvp: 'Average Approved value',
    ransomCheckOvp: 'Average Delivered value',
    ransomCheckOvpAndRansomPercentOvp: 'Average order value and Delivered %',
    workTime: 'Worked time',
    efficiency: 'Efficiency',
    kln: 'QA',
    recommendedGrade: 'Recommended grade',
    grade: 'Grade',
    KpiNorms: 'Norms',
    recountGrades: 'Recalculate',
    lastRecount: 'Last calculation:',
    recountNotifTitle: 'Recalculate grades',
    recountNotifDescription: 'You will receive a notification when grades will be recalculated.',
    normsKpiForGrade: 'Norms for increased grade',
    hot: 'Hot sales',
    resales: 'Repeat sales',
    condition: 'Conditions',
    intern: 'Intern',
    manager: 'Manager',
    specialist: 'Specialist',
    professional: 'Professional',
    master: 'Master',
    workTimeTable: 'Worked time',
    effectiveness: 'Efficiency',
    checkAndRansomPercent: 'Delivered value and % norm',
    checkAndApprove: 'Approved value norm',
    checkAndRansom: 'Delivered value norm',
    checkAndApprovePercent: '% Approved norms',
    ransomCheck: '% Delivered norms',
    knlScores: 'QA points',
    error: 'Calculation error',
    errorGrade: 'Unable to calculate grade',
  },
  cityImport: {
    addFile: 'Upload file',
    loadFromFile: 'Upload from file',
    downloadTemplateYouCanHere: 'Download template here',
    preview: 'Preview',
    correctRows: 'Rows without errors',
    rowsWithErrors: 'Rows with errors',
    country: 'Country',
    modalImportDesc: 'Only rows with no errors will be uploaded.<br/>Do you want to continue?',
    importCity: 'Import cities',
    field: {
      region: 'State',
      district: 'District',
      city: 'City',
      deliveryType: 'Delivery type',
      deliveryService: 'Delivery service',
      delivery_service: 'Delivery service',
      warehouse: 'Warehouse',
      errors: 'Info',
      name: 'City',
      serviceName: 'Warehouse',
      connectService: 'Connect DS',
    },
    tooltip: 'Two or more duplicate cities found',
  },
  delivery: {
    deliveryCourier: 'Courier delivery',
    deliveryPoints: 'Delivery point',
    deliveryPost: 'Postal delivery',
    list: {
      bareCode: 'Tracking code',
      orderList: 'Order items',
      productsAmount: 'Products quantity',
      dateApprove: 'Approved date',
      deliveryWaiting: 'Awaiting shipment',
      deliveryDate: 'Delivery date',
      city: 'City',
      district: 'District',
      region: 'State',
      country: 'Country',
      catalog: 'Catalog',
      terminalPayment: 'Card payment',
      dateToDeliveryService: 'DS export date',
      comments: 'Comment',
      deliveryServiceAnswer: 'DS response',
      offers: 'Offer',
      postAddress: 'Address',
      operator: 'Operator',
    },
    filter: {
      countries: 'Countries',
      regions: 'States',
      districts: 'Districts',
      cities: 'Cities',
      offer: 'Offers',
      groupOffers: 'Offer groups',
      operator: 'Operators',
      terminalPayment: 'Card payment',
      tags: 'Tags',
    },
  },
  smsCampaign: {
    title: 'SMS campaigns',
    table: {
      id: 'ID',
      name: 'Title',
      template: 'Template',
      sender: 'Sender name',
      orderStatus: 'Order status',
      dayInStatus: 'Days in status',
      active: 'Campaign status',
    },
    filter: {
      date: 'Date',
      active: 'State',
      deliveryServices: 'Delivery service',
      offers: 'Offers',
      country: 'Countries',
      templates: 'Templates',
      nameSender: 'Sender name',
    },
    form: {
      title: 'Auto SMS campaign',
      titleManual: 'Manual SMS campaign',
      titleItem: 'Campaign settings',
      titleItemManual: 'General',
      titleMain: 'General',
      name: 'Title',
      template: 'Template',
      sender: 'Sender name',
      country: 'Country',
      delivery: 'Delivery service',
      active: 'Campaign status',
      offers: 'Offers',
      orderStatus: 'Order status',
      startDate: 'Status date from',
      startDateManual: 'Campaign start date',
      endDate: 'Status date to',
      dayInStatus: 'Days in status',
      service: 'Delivery service',
      operatorSms: 'SMS provider',
    },
    automatically: 'Automatically',
  },
  smsList: {
    title: 'SMS campaign report',
    id: 'Order No',
    status: 'SMS',
    smsCount: 'SMS Qty',
    sendDate: 'Date/time',
    smsStatus: 'SMS statuses',
    getStatus: 'Get status',
    sendMessage: 'Delivered',
  },
  smsStatuses: {
    sent: 'Shipped',
    pending: 'Pending',
    error: 'Error',
  },
  orderStatuses: {
    accepted: 'New order',
    callback: 'Callback',
    approved: 'Approved',
    cancelled: 'Cancelled',
    shipped: 'Sent',
    delivered: 'Delivered',
    no_ransom: 'Return',
    packaging: 'Packaging',
    lost_package: 'Lost package',
    // old statuses below
    processing: 'Processing',
    incorrect: 'Invalid',
    canceled: 'Canceled',
    awaiting_ransom: 'Awaiting delivery',
    call: 'Call',
    trash: 'Trash',
    paid: 'Paid',
    partially_paid: 'Partially paid',
    chooseCols: 'Select columns',
    suspicious: 'Suspicious',
    all: 'All',
  },
  callStatuses: {
    dialing: 'Dialing',
    call: 'Call',
    success: 'Success',
    failed_attempt: 'Failed attempt',
    failed: 'Failed',
  },
  evaluationOptions: {
    yes: 'Yes',
    no: 'No',
    partial: 'Partially',
    dont_consider: 'Disregard',
  },
  offerNorms: {
    offer: 'Offer',
    averageCheck: 'Approved average value',
    approve: 'Approved',
    ransomCheck: 'Delivered order value',
    ransom: 'Sent',
    top: 'Top',
    avg: 'Average',
    mediana: 'Median',
    norma: 'Norm',
  },
  salesScriptCopy: {
    title: 'Enter title',
    copy: 'Copy - ',
  },
  salesModel: {
    salesModel: 'Sales model',
    newSalesModel: 'Add',
    salesModelAdd: 'Add sales model',
    salesModelEdit: 'Edit sales model',
    name: 'Title',
    salesType: 'Sales type',
    slug: 'Title',
  },
  managerDashboard: {
    title: 'Dashboard',
    ordersChartTitle: 'Orders',
    hangingRansomChartTitle: 'Delivery services debts',
    deliveryServiceOrderChartTitle: 'Shipped, delivered and paid by DS',
    approvesPensionerChartTitle: 'Approved 55+',
    deliveryServiceDebtChartTitle: 'Delivery services debts',
    approvesByTimeChartTitle: 'Approved by hours',
    amountAndAverageChartTitle: 'Qty and average time in statuses',
    noRansom72HoursTitle: 'Canceled/Undelivered 72h. after Approved',
    totalCancelAfterApprove: 'Total Canceled after Approved',
    cancel72hours: 'Canceled 72h.',
    noRansom72hours: 'Canceled 72h.',
    allApprovePerDay: 'Total Approved per day',
    approvePerDay: 'Approved 55+ per day',
    orderPerDay: 'Total New orders per day',
    approvesPerDay: 'Total Approved per day',
    approves: 'Approved',
    avgTimeToApprove: 'Average time till approve',
    send: 'Shipped',
    amount: 'Amount',
    redeemed: 'Delivered',
    paid: 'Paid',
    widgets: 'Widgets',
    overThePast: 'last 30 days',
    status: 'Status',
    count: 'Qty',
    sum: 'Total',
    groupOperators: 'Operator group',
    salesType: 'Sales type',
    deliveryType: 'Delivery type',
    deliveryService: 'Delivery service',
    statusHangingRansom: {
      packaging: 'Packaging',
      suspicious: 'Suspicious',
      shipped: 'Shipped',
      processing: 'Processing',
      approved: 'Approved',
      canceled: 'Canceled',
      accepted: 'New order',
      callback: 'Callback',
      awaiting_ransom: 'Awaiting delivery',
      ransom: 'Sent',
      no_ransom: 'Return',
      debt: 'Delivery service debt',
      delivered: 'Delivered',
      partially_paid: 'Partially paid',
      paid: 'Paid',
      incorrect: 'Invalid',
      trash: 'Trash',
      total: 'Total',
    },
    dates: {
      shortDays: 'd.',
      shortHours: 'h.',
      shortMinutes: 'm.',
      shortSeconds: 's.',
    },
  },
  salaryBonus: {
    salaryBonus: 'Call center salary matrix',
    country: 'Country',
    operatorGrade: 'Operator grade',
    salesModel: 'Sales model',
    checkCurrency: 'Currency',
    checkFrom: 'Order value from',
    checkUp: 'Order value to',
    basePayOperator: 'Base operator payment',
    exportFileName: 'Salary matrix template',
    additionalPaymentForGradeOperator: 'Operator increased grade payment',
    additionalPaymentForCheckOperator: 'Operator increased order value payment',
    basicPaymentForTeamLeadTeamLead: 'Base team leader payment',
    additionalPaymentForGradeTeamLead: 'Team leader increased grade payment',
    additionalPaymentForCheckTeamLead: 'Team leader increased order value payment',
    totalPaymentOperator: 'Total operator payment',
    totalPaymentTeamLead: 'Total team leader payment',
    filterCountry: 'Countries',
    filterSalesModel: 'Sales models',
    filterOperatorGrade: 'Operator grade',
    import: 'Import data for Call center salaries',
    infoImport:
      'Download the template, add data and upload it back.<br/>All data in the system will be rewritten.',
    importData: 'Import',
    exportTable: 'Download template here',
    modalImport:
      'Only the lines without errors will be imported.<br/> New numbers will be used in the closest salary calculation.',
    warningText: 'All data in the system will be rewritten',
    field: {
      amountFrom: 'Order value from',
      amountTo: 'Order value to',
      baseOperatorAmount: 'Operator base payment',
      baseTeamLeaderAmount: 'Team leader base payment',
      country: 'Country',
      grade: 'Operator grade',
      operatorCheckSurcharge: 'Operator increased order value payment',
      operatorGradeSurcharge: 'Operator increased grade payment',
      salesModel: 'Sales model',
      teamLeaderCheckSurcharge: 'Team leader increased order value payment',
      teamLeaderGradeSurcharge: 'Team leader increased grade payment',
      errors: 'Info',
    },
  },
  dashboardNotifications: {
    notifications: 'Notifications',
    clear: 'Clear',
    unread: 'New',
    read: 'Viewed',
    listIsEmpty: 'The list is empty',
    loading: 'Loading',
  },
  notifications: {
    operator_not_ready_for_callback: 'Callback operator is missing',
    duty_manager_absent: 'No manager on duty',
    personal_break_time_limit: 'Break time limit',
    personal_break_time_left: 'Break time left',
    order_manager_help: 'Assistance request',
    product_deactivated: 'Product deactivation',
    mng_handing_ransom: 'Delivery service debts',
    grade_recount_finish: 'Grades recalculation',
    currency_synchronize_error: 'Synchronization error',
    metaship_synchronize_error: 'Synchronization error',
  },
  prepayment: {
    title: 'Prepayment 1',
    cardNumber: 'Bank account',
    fullName: 'Full name',
    amount: 'Amount',
    comment: 'Comment',
    average: 'Average value',
    total: 'Total',
    supervisor: 'Manager',
    userGroups: 'User group',
    titleTwo: 'Prepayment 2',
    fileName1: '1_prepayment_operators_',
    fileName2: '2_prepayment_operators_',
    fileNameTeamLead1: '1_prepayment_teamleaders_',
    fileNameTeamLead2: '2_prepayment_teamleaders_',
    export: 'Export',
  },
  additionalProjects: {
    title: 'Cold sales efficiency',
    hot: 'Hot',
    correct: 'Valid',
    approve: 'Approved',
    ransom: 'Sent',
    efficiencyAdditionalProjects: 'Cold sales efficiency',
    catalog: 'Catalog',
    rub: 'eur',
    catalogs: 'Catalogs',
    repeated: 'Repeat sales',
    Cancelation: 'Canceled',
    nonRedemption: 'Canceled',
    income: 'Income',

    filterOffers: 'Offers',
    filterOfferGroup: 'Offer groups',
    filterWeb: 'Webs',

    offer: 'Offer',
    web: 'Web',
  },
  operatorDashboard: {
    applications: 'Orders',
    topOperatorsTooltip: 'Top operators by approved orders',
    dailyEarningTooltip: 'Data is updating every hour',
    ordersInQueue: 'Amount of orders in queue',
    currentGrade: 'Current grade',
    dailyEarning: 'Daily earnings',
    schedule: 'Work schedule',
    approve: 'approved',
    earningTable: {
      online: 'Online',
      approves: 'Approved',
      approve: 'Approved',
      ransoms: 'Delivered',
      ransom: 'Sent',
      payment: 'Payment',
      bonuses: 'Base payments',
      penalty: 'Penalties',
      hanging: 'Fines',
      profit: 'Bonuses',
      hours: 'hours',
      uah: 'uah',
      eur: 'eur',
    },
    statusHangingRansom: {
      packaging: 'Packaging',
      approved: 'Approved',
      canceled: 'Canceled',
      delivered: 'Delivered',
      paid: 'Paid',
      partially_paid: 'Partially paid',
      awaiting_ransom: 'Awaiting delivery',
      ransom: 'Sent',
      no_ransom: 'Return',
      shipped: 'Shipped',
      callback: 'Callback',
      debt: 'Delivery service debt',
      accepted: 'New order',
      processing: 'Processing',
      incorrect: 'Invalid',
      trash: 'Trash',
      total: 'Total',
    },
    noAvailableData: 'No data',
    hotTop: 'Top hot sales',
    top: 'Top operators',
    ratingError: 'Calculation error',
    repeatedTop: 'Top cold sales',
    yourRating: 'Your score',
    dayType: 'Day type',
    reason: 'Reason',
    scheduleWork: 'Schedule',
    changeDate: 'Edit',
    scheduleSub: {
      work_day: 'Work day',
      day_off: 'Day off',
      vacation: 'Vacation',
      sick_leave: 'Sick leave',
      session: 'Session',
      absent: 'Absent',
    },
  },
  calendar: {
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
  },
  klnStatistic: {
    evaluationPoints: 'Assessment items',
    noDataMessage: 'Not enough data',
    groupsInformation: 'Info by groups',
    moreInfo: 'More info',
    selectAll: 'Select all',
    clear: 'Clear',
    filter: {
      groupOperators: 'Operator groups',
      operators: 'Operators',
      offerGroups: 'Offer groups',
      offers: 'Offers',
      niches: 'Niches',
      salesTypes: 'Sales types',
      salesModels: 'Sales models',
      country: 'Country',
      regions: 'States',
      deliveryTypes: 'Delivery types',
      deliveryServices: 'Delivery services',
    },
  },
  klnAssessment: {
    title: 'QA assessments',
    titleOperator: 'Operator QA assessment',
    export: 'Export',
    orderNumber: 'Order No',
    status: 'Status',
    niches: 'Niche',
    offer: 'Offer',
    operator: 'Operator',
    managerKln: 'QA Manager',
    dateValue: 'Assessment date',
    updatedAt: 'Update date',
    operatorsGroup: 'Operator groups',
    total: 'Total',
    workStatus: {
      created: 'Not processed',
      pending: 'Processing',
      finished: 'Processed',
    },
    addAudio: 'Add recording',
    listAudio: 'Recordings list',
    descInfoModal:
      'The assessment is in "Processing" status. If somebody is editing it, after pressing "Save" all data will be rewritten',
    callTimeItems: {
      low: 'up to 4 min',
      middle: '6-10 min',
      top: '10+ min',
    },
    callTime: 'Call duration',
  },
  webAnalytica: {
    title: 'Affiliate payments',
    date: 'Date',
    web: 'Web',
    source: 'Source',
    order: 'Order',
    offer: 'Offer',
    typeProduct: 'Product type',
    bid: 'Affiliate payment',
    country: 'Country',
  },
  questionnairesStatistics: {
    statistics: 'Statistics',
    form: 'Survey',
    period: 'Period',
    btnConfirm: 'To order',
    title: 'Survey of',
    titleTwo: 'on Order No',
    questionAnswer: 'Answer',
    hotOperator: 'Order operator',
    operator: 'Survey operator',
    export: 'Export',
    back: 'To list',
    total: 'Total',
  },
  paymentHold: {
    paymentHold: 'Fine',
    paymentHolds: 'Fines',
    main: 'General',
    name: 'Title',
    amount: 'Amount',
    description: 'Description',
    penaltyRu: 'Amount',
    comment: 'Comment',
    actions: 'Actions',
  },
  paymentProfit: {
    paymentProfit: 'Bonus',
    paymentProfits: 'Bonuses',
    main: 'General',
    name: 'Title',
    amount: 'Amount',
    description: 'Description',
    comment: 'Comment',
    actions: 'Actions',
  },
  modalSuccessError: {
    close: 'Close',
    success: 'Success',
    error: 'Error',
    successSync: 'Successfully synchronized',
    errorSync: 'Synchronization error',
  },
  notFound: {
    title: 'Something went wrong...',
    text: 'Page does not exist, or you do not have permission to access it',
    btnText: 'Home page',
  },
  regionImport: {
    regionImport: 'Import states',
    country: 'Country',
    preview: 'Preview',
    importData: ' Import data',
    addFile: 'Upload file',
    downloadTemplateYouCanHere: 'Download template here',
    field: {
      name: 'State',
      code: 'Code',
      timezone: 'Timezone',
      errors: 'Info',
    },
  },
  districtImport: {
    districtImport: 'Import districts',
    country: 'Country',
    preview: 'Preview',
    importData: ' Import data',
    addFile: 'Upload file',
    downloadTemplateYouCanHere: 'Download template here',
    field: {
      region: 'State',
      name: 'District',
      errors: 'Info',
    },
  },
  hangingRansom: {
    title: 'DS debts',
    day: 'Day',
    totalRansom: 'Delivered',
    sum: 'Amount',
    paid: 'Paid',
    percent: '%',
    partPaid: 'Partially paid',
    sumCheck: 'To be paid',
    sumPaid: 'Paid',
    waitPaid: 'Awaiting payment',
    countOrderCredit: 'Orders with DS debt',
    creditCd: 'Delivery service debt',
    total: 'Total',
    offer: 'Offer',
    statusChart: {
      paid: 'Paid:',
      waitPaid: 'Awaiting payment:',
      partPaid: 'Partially paid:',
      deptCd: 'Debt:',
      total: 'Delivered:',
    },
    filters: {
      offer: 'Offer',
      groupOffer: 'Offer groups',
      tags: 'Tags',
      offerNiche: 'Niche',
      salesModel: 'Sales model',
      deliveryService: 'Delivery service',
      deliveryType: 'Delivery type',
      countries: 'Country',
      regions: 'State',
      districts: 'District',
      cities: 'City',
      userGroups: 'User group',
      web: 'Web',
      questionnaires: 'Cold orders survey',
    },
    filtersSelect: {
      month: 'Month',
      country: 'Country',
      region: 'State',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      delivery_service: 'Delivery service',
      sales_model: 'Sales model',
      offer: 'Offers',
    },
    filterDateBy: {
      createdAt: 'By Created',
      approve: 'By Approved',
      shipped: 'By Shipped ',
      delivered: 'By Delivered',
    },
    count: 'Qty',
    amountOfDebt: 'Debt',
    unpaidOrders: {
      title: 'Orders with DS debt',
      btn: 'Orders list',
      orderStatus: 'Order status',
    },
  },
  averageCheck: {
    title: 'Average order value',
    hours: 'Hour',
    day: 'Day',
    orders: 'Orders',
    total: 'Total',
    correct: 'Valid',
    wasCall: 'Called',
    confirmed: 'Approved',
    counts: 'Qty',
    percentCorrect: '% from Valid',
    percentTotal: '% from Total',
    totalMinutes: 'Time (sec.)',
    check: 'Order value',
    sent: 'Shipped',
    noRansom: 'No ransom',
    percentConfirmed: '% from Approved.',
    redeemedAverageCheck: 'Delivered',
    percentSent: '% from Delivered',
    male: 'Male',
    female: 'Female',
    filters: {
      countries: 'Countries',
      regions: 'States',
      city: 'Cities',
      deliveryService: 'Delivery services',
      offers: 'Offers',
      offersGroup: 'Offer groups',
      offersNiche: 'Niches',
      operators: 'Operators',
      landings: 'Landings',
      webs: 'Webs',
      salesModel: 'Sales models',
      productType: 'Product types (offer)',
      operatorGroups: 'User groups',
      age: 'Age',
      gender: 'Gender',
    },
    select: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      processing_date: 'Processing date',
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      countries: 'Country',
      region: 'State',
      city: 'City',
      operator_group: 'Operator group',
      operator: 'Operator',
      landing: 'Landing',
      web: 'Web',
      reject_reason: 'Canceled reason',
      delivery_service: 'Delivery service',
      sales_model: 'Sales model',
      product_type: 'Product type (CRM)',
      age: 'Age',
      gender: 'Gender',
      warehouse: 'Shipping warehouse',
      order_check: 'Order value',
    },
    filterDateBy: {
      createdAt: 'By Created',
      processingDate: 'By Processing',
      approveDate: 'By Approved',
      shippedDate: 'By Shipped',
      ransomDate: 'By Delivered',
    },
    null: '-',
  },
  deliveryExcludedRules: {
    main: 'General',
    name: 'Title',
    country: 'Country',
    countries: 'Countries',
    offers: 'Offers',
    regions: 'Excluded states for Delivery service',
    deliveryServices: 'Excluded delivery services',
    list: {
      deliveryServices: 'Excluded Delivery services',
      regions: 'States',
    },
  },
  legalPersonsStats: {
    title: 'Companies report',
    countries: 'Countries',
    regions: 'States',
    deliveryTypes: 'Delivery types',
    levelTypes: {
      warehouse: 'Shipping warehouse',
      organization: 'Company',
      deliveryService: 'Delivery service',
      deliveryType: 'Delivery type',
      offer: 'Offer',
      product: 'Product',
      country: 'Country',
      region: 'State',
    },
    sentVolume: 'Shipped',
    volumePercent: '% volume',
    volumePercentTotal: '% volume total',
    dateOfFirstShipment: 'First shipment date',
    dateOfLastShipment: 'Last shipment date',
    averageCheck: 'Average order value',
    total: 'Total shipped',
  },
  deliveryServiceAnalytic: {
    title: 'Delivery services analytics',
    regions: 'States',
    redeemed: 'Delivered',
    sent: 'Shipped',
    percent: '%',
    select: {
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      country: 'Country',
      region: 'State',
      city: 'City',
      user_group: 'Operator group',
      operator: 'Operator',
      landing: 'Landing',
      web: 'Web',
      delivery_service: 'Delivery service',
      sales_model: 'Sales model',
      product_type: 'Product type (CRM)',
      warehouse: 'Shipping warehouse',
    },
    isActiveCity: 'City status',
    groupDateBy: {
      year: 'Year',
      quarter: 'Quarter',
      month: 'Month',
      week: 'Week',
      day: 'Day',
    },
    totalType: {
      totalAll: 'Whole period',
      totalCurrent: 'Filtered period',
    },
    filters: {
      deliveryServices: 'Delivery services',
      countries: 'Countries',
      regions: 'States',
      districts: 'Districts',
      cities: 'Cities',
      deliveryTypes: 'Delivery types',
      warehouses: 'Warehouses',
      checkFrom: 'Order value from',
      checkTo: 'Order value to',
      offers: 'Offers',
      products: 'Products',
      groupDate: 'Group by period',
      totalType: 'Total',
    },
    filterDateBy: {
      createdAt: 'By Created',
      approveDate: 'By Approved',
      firstApproveDate: 'By first Approved date',
      shippedDate: 'By Shipped',
      deliveryDate: 'By delivery date',
      ransomDate: 'By Delivered',
      lastStatus: 'By final status date',
    },
    totalAll: 'Whole period',
    shippedOrders: 'Shipped orders',
    ransom: 'Sent',
    activate: 'Activate',
    deactivated: 'Deactivate',
  },
  deliveryPointsImport: {
    title: 'Import Delivery points',
    modalImportDesc: 'Only rows with no errors will be uploaded.<br/>Do you want to continue?',
    preview: 'Preview',
    correctRows: 'Rows without errors',
    rowsWithErrors: 'Rows with errors',
    downloadTemplateYouCanHere: 'Download template here',
    addFile: 'Upload file',
    labels: {
      country: 'Country',
      status: 'Status',
    },
    field: {
      name: 'Title',
      city: 'City',
      region: 'State',
      district: 'District',
      deliveryService: 'Delivery service',
      address: 'Address',
      phone: 'Phone number',
      integrationKey: 'Integration key',
      minDays: 'Min days',
      maxDays: 'Max days',
      comment: 'Comment',
      workTime: 'Working hours',
      terminal: 'Card payment',
      errors: 'Info',
    },
  },
  welcomePage: {
    singleSolution: 'Single CRM for:',
    callCentre: 'Call center',
    logistics: 'Logistics',
    noticeDepartment: 'Notifications',
    management: 'Management',
  },
  distributionCheck: {
    title: 'Order value distribution',
    totalApprove: 'Approved',
    filters: {
      offer: 'Offer',
      offerGroups: 'Offer groups',
      niche: 'Niche',
      web: 'Web',
      landings: 'Affiliate website',
      salesModel: 'Sales model',
      salesType: 'Sales type',
      operators: 'Operator',
      userGroups: 'User group',
      country: 'Country',
      region: 'State',
    },
  },
  userSalary: {
    title: 'Operator salary',
    total: 'Total',
    cardNumber: 'Bank account',
    fullName: 'Full name',
    amount: 'Amount',
    comment: 'Comment',
    supervisor: 'Manager',
    userGroups: 'User group',
    fileName: 'Operators salaries',
    currency: 'Salary currency',
  },
  buybackDynamics: {
    title: 'Delivered by days',
    total: 'Total',
    table: {
      dayDifference: 'Difference',
      redeemed: 'Delivered',
      redeemedPercent: '% Delivered',
      sent: 'Shipped',
      notRedeemed: 'Undelivered',
      redemptionVolume: 'Delivered volume',
    },
    filters: {
      deliveryServices: 'Delivery services',
      countries: 'Countries',
      regions: 'States',
      deliveryTypes: 'Delivery types',
      warehouses: 'Warehouses',
      checkFrom: 'Order value from',
      checkTo: 'Order value to',
      callCount: 'Call count',
      orderId: 'Order id',
      offers: 'Offers',
      products: 'Products',
      tags: 'Tags',
    },
    filterDateBy: {
      created_at: 'By Created',
      approve: 'By Approved',
      first_approve: 'By first Approved date',
      shipped: 'By Shipped',
      awaiting_ransom: 'By Awaiting delivery',
    },
  },
  reportWithoutBarcode: {
    city: 'City',
    downloadFileName: 'No tracking code report',
  },
  callController: {
    title: 'Calls',
    id: 'ID',
    operator: 'Operator',
    orderId: 'Order No',
    status: 'Status',
    showNumber: 'Show phone',
    startOfCall: 'Started',
    duration: 'Duration',
    callRecord: 'Call recording',
    date: 'Date',
    time: 'Time',
    order: 'Order',
    offer: 'Offer',
    type: 'Type',
    types: {
      auto: 'Autodialing',
      manual: 'Manual call',
    },
    filter: {
      operatorGroup: 'Operator group',
    },
    resultCall: 'Dialer status',
  },
  rejectionStatistics: {
    title: 'Rejected report',
    table: {
      day: 'Day',
      total: 'Total',
      rejected: 'Rejected',
    },
    total: 'Total',
    filtersGroup: {
      status_group: 'Status',
      reject_reason: 'Status reason',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      operator_groups: 'Operator group',
      country: 'Country',
      web: 'Web',
      operator: 'Operator',
      sales_model: 'Sales model',
      sales_type: 'Sales type',
    },
    filterDateBy: {
      createdAt: 'By Created',
      approve: 'By Approved',
      processing: 'By Processing',
    },
    filters: {
      groupOperators: 'Operator groups',
      operators: 'Operators',
      groupOffers: 'Offer groups',
      offers: 'Offers',
      niches: 'Niches',
      salesTypes: 'Sales types',
      salesModel: 'Sales models',
      countries: 'Countries',
      regions: 'States',
      deliveryTypes: 'Delivery types',
      deliveryServices: 'Delivery services',
    },
    statuses: {
      canceled: 'Canceled',
      callback: 'Callback',
      trash: 'Trash',
    },
  },
  callCentreStatistics: {
    title: 'Call center report',
    table: {
      day: 'Day',
      allOrders: 'All orders',
      approve: 'Approved',
      approveFromAll: 'Approved from all',
      approveFromCorrect: 'Approved from correct',
      ransom: 'Sent',
      delivered: 'Delivered',
      productTypes: 'Product types',
      processing: 'Processing',
      delivery: 'Delivery',
      indicators: 'Target indicators',
      targetCheck: 'Target check',
      targetApprove: 'Target approve',
      approveCheck: 'Approve check',
      targetRansomPercent: 'Target ransom percent',
      total: 'Total',
      totalOrder: 'Total received',
      correct: 'Valid',
      processed: 'Processed',
      salesAmountPerHour: 'Sales amount per hour',
      call: 'Success',
      avgCheck: 'Average order value',
      avgOrderTime: 'Average order time',
      avgTime: 'Call avg time',
      fiftyFive: '55+',
      confirmCPA: 'Approved Hot sales',
      basic: 'Main',
      substitute: 'Substitute',
      alternative: 'Alternative',
      cross: 'Additional in set',
      gift: 'Gift',
      coupon: 'Coupon',
      additionallyCross: 'Additional',
      another: 'Other',
      calling: 'In dialer queue',
      callback: 'Callback',
      trash: 'Trash',
      cancel: 'Canceled',
      removeCall: 'Removed from dialer queue',
      deliveryOn: 'Delivery included',
      countApprove: 'Ransom',
      processingTime: 'Processing time'
    },
    select: {
      hour: 'Hour',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      country: 'Country',
      region: 'State',
      city: 'City',
      operator_groups: 'Operator group',
      operator: 'Operator',
      landing: 'Landing',
      web: 'Web',
      reject_reason: 'Status reason',
      delivery_service: 'Delivery service',
      sales_model: 'Sales model',
      sales_type: 'Sales type',
      product_type: 'Product type (CRM)',
      age: 'Age',
      gender: 'Gender',
      product: 'Product',
    },
    filterDateBy: {
      createdAt: 'By Created',
      approve: 'By Approved',
      processing: 'By Processing',
      shipped: 'By Shipped ',
      delivered: 'By Delivered',
    },
    filters: {
      offerGroups: 'Offer groups',
      offers: 'Offer',
      products: 'Products',
      tags: 'Tags',
      offerNiche: 'Niche',
      salesModel: 'Sales model',
      deliveryServices: 'Delivery service',
      deliveryType: 'Delivery type',
      country: 'Country',
      region: 'State',
      city: 'City',
      userGroups: 'User group',
      operators: 'Operators',
      web: 'Web',
      landing: 'Landing',
      salesType: 'Sales type',
      gender: 'Gender',
      questionnaires: 'Cold orders survey',
    },
    gender: {
      male: 'Male',
      female: 'Female',
    },
  },
  suspiciousOrders: {
    title: 'Suspicious orders',
  },
  reconfirmationStatistic: {
    title: 'Reconfirmations',
    select: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      operator: 'Operator',
      operator_groups: 'Operator group',
      delivery_service: 'Delivery service',
      delivery_type: 'Delivery type',
      sales_model: 'Sales model',
      sales_type: 'Sales type',
      age: 'Age',
      gender: 'Gender',
    },
    table: {
      total: 'Total orders',
      call: 'Successful call',
      approve: 'Approved',
      cancel: 'Canceled',
      shipped: 'Shipped',
      awaitingRansom: 'Awaiting delivery',
      ransom: 'Delivered from Approved',
      noRansom: 'Canceled from Approved',
      failedCall: 'Failed call',
      failedCallShipped: 'Shipped',
      failedCallAwaitingRansom: 'Awaiting delivery',
      failedCallRansom: 'Delivered from Failed call',
      failedCallNoRansom: 'Canceled from Failed call',
    },
    callPP: 'Successful call RC',
    failCallPP: 'Failed call RC',
    status: 'Status',
    count: 'Qty',
    chart: {
      approve: 'Approved:',
      cancel: 'Canceled:',
      shipped: 'Shipped:',
      awaitingRansom: 'Awaiting delivery',
      ransom: 'Sent:',
      noRansom: 'Canceled:',
      total: 'Successful call RC:',
      totalFail: 'Failed call RC:',
    },
    filters: {
      offerGroups: 'Offer groups',
      offer: 'Offer',
      offerNiche: 'Niche',
      salesModel: 'Sales model',
      salesType: 'Sales type',
      userGroups: 'User group',
      operators: 'Operators',
      country: 'Country',
      region: 'State',
      tags: 'Tags',
      deliveryType: 'Delivery type',
      deliveryService: 'Delivery service',
    },
    gender: {
      male: 'Male',
      female: 'Female',
    },
  },
  callStatistics: {
    title: 'Call statistics',
    select: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      operator: 'Operator',
      country: 'Country',
      operator_groups: 'Operator group',
      delivery_service: 'Delivery service',
      sales_model: 'Sales model',
      sales_type: 'Sales type',
      age: 'Age',
      gender: 'Gender',
      web: 'Web',
    },
    newReport: {
      totalCalls: 'Total calls',
      avgTime: 'Average time',
      totalTime: 'Total time',
    },
    filterDateBy: {
      auto: 'By autodialing',
      manual: 'By manual calls',
    },
    total: 'Total calls',
    noCall: 'Failed',
    call: 'Successful',
    quantity: 'Qty',
    averageDuration: 'Average duration',
    totalDuration: 'Total duration',
    lessThirty: 'Less than 30 sec',
    lessTen: 'from 30 sec to 10 min',
    moreTen: 'More than 10 min',
    filters: {
      groupOperators: 'Operator groups',
      operators: 'Operators',
      offerGroups: 'Offer groups',
      offers: 'Offers',
      niches: 'Niches',
      salesTypes: 'Sales types',
      salesModels: 'Sales models',
      country: 'Country',
      regions: 'States',
      deliveryTypes: 'Delivery types',
      deliveryServices: 'Delivery services',
      webs: 'Webs',
    },
  },
  callCenterPrice: {
    title: 'Call center expenses',
    month: 'Month',
    offer: 'Offer',
    country: 'Country',
    currency: 'Currency',
    salesModel: 'Sales model',
    salesType: 'Sales type',
    typeDefaultOffer: 'Offer main product type',
    price: 'Cost',
    dateDownload: 'Created at',
    operatorDownload: 'Created by',
    import: 'Import Call center expenses',
    addFile: 'Upload file',
    infoImport:
      'Download the template, add data and upload it back.<br/>All data in the system will be rewritten.',
    importData: 'Import',
    exportTable: 'Download template here',
    preview: 'Preview',
    correctRows: 'Rows without errors',
    rowsWithErrors: 'Rows with errors',
    warningText: 'All data in the system will be rewritten!',
    modalImportDesc: 'Only rows with no errors will be uploaded.<br/>Do you want to continue?',
    filters: {
      salesModel: 'Sales model',
      country: 'Country',
      warehouse: 'Warehouse',
      productType: 'Product type (CRM)',
    },
    field: {
      country: 'Country',
      salesModel: 'Sales model',
      productType: 'Offer main product type',
      price: 'Cost',
      currency: 'Currency',
      errors: 'Info',
      offer: 'Offer',
    },
  },
  deliveryTax: {
    title: 'Fines and taxes',
    month: 'Month',
    country: 'Country',
    currency: 'Currency',
    deliveryService: 'Delivery service',
    productType: 'Offer main product type',
    bid: 'Rate',
    dateDownload: 'Created at',
    author: 'Created by',
    import: 'Import Fines and taxes',
    addFile: 'Upload file',
    infoImport:
      'Download the template, add data and upload it back.<br/>All data in the system will be rewritten.',
    importData: 'Import',
    exportTable: 'Download template here',
    preview: 'Preview',
    correctRows: 'Rows without errors',
    rowsWithErrors: 'Rows with errors',
    warningText: 'All data in the system will be rewritten!',
    modalImportDesc: 'Only rows with no errors will be uploaded.<br/>Do you want to continue?',
    field: {
      country: 'Country',
      deliveryService: 'Delivery service',
      delivery: 'Delivery service',
      productType: 'Offer main product type',
      rate: 'Rate',
      currency: 'Currency',
      errors: 'Info',
    },
  },
  logisticPrice: {
    title: 'Logistics expenses',
    import: 'Import Logistics expenses',
    month: 'Month',
    country: 'Country',
    currency: 'Currency',
    deliveryService: 'Delivery cost',
    backDeliveryCost: 'Return delivery cost',
    createdAt: 'Created at',
    createdBy: 'Created by',
    addFile: 'Upload file',
    exportTable: 'Download template here',
    preview: 'Preview',
    importData: 'Import',
    correctRows: 'Rows without errors',
    rowsWithErrors: 'Rows with errors',
    infoImport:
      'Download the template, add data and upload it back.<br/>All data in the system will be rewritten.',
    warningText: 'All data in the system will be rewritten!',
    modalImportDesc: 'Only rows with no errors will be uploaded.<br/>Do you want to continue?',
    field: {
      country: 'Country',
      deliveryService: 'Delivery service',
      currency: 'Currency',
      price: 'Delivery cost',
      backPrice: 'Return delivery cost',
      errors: 'Info',
    },
  },
  telephony: {
    title: 'Dialer',
    login: 'Dialer login',
    password: 'Dialer password',
  },
  financialReport: {
    title: 'Financial report',
    select: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      operator_groups: 'Operator group',
      operator: 'Operator',
      delivery_service: 'Delivery service',
      delivery_type: 'Delivery type',
      country: 'Country',
      region: 'State',
      city: 'City',
      sales_model: 'Sales model',
      sales_type: 'Sales type',
      age: 'Age',
      gender: 'Gender',
      web: 'Web',
      landing: 'Landing',
      organization: 'Company',
      product_type: 'Product type (CRM)',
      warehouse: 'Warehouse',
    },
    filterDateBy: {
      createdAt: 'By Created',
      approve: 'By Approved',
      shipped: 'By Shipped ',
      delivered: 'By Delivered',
    },
    total: 'Total',
    countOrders: 'Total orders',
    countOrdersTotal: 'Total',
    countOrdersApprove: 'Approved',
    paidLead: 'Paid lead',
    send: 'Shipped',
    ransom: 'Sent',
    revenue: 'Income',
    toOrder: 'Per order',
    costPrice: 'Order cost',
    percentIncome: '% of income',
    leadCosts: 'Affiliate payments expenses',
    onPayment: 'Per paid',
    onConfirmation: 'Per approved',
    shippingCost: 'Delivery expenses',
    returnShippingCost: 'Return delivery expenses',
    ccExpenses: 'Call center expenses',
    deliveryTax: 'Fines and taxes',
    costsNoRansom: 'Canceled expenses',
    expenses: 'Expenses',
    profit: 'Profit',
    filters: {
      offer: 'Offer',
      offerGroups: 'Offer groups',
      niche: 'Niche',
      salesModel: 'Sales model',
      salesType: 'Sales type',
      operator: 'Operator',
      userGroups: 'User group',
      country: 'Country',
      region: 'State',
      city: 'City',
      tags: 'Tags',
      deliveryType: 'Delivery type',
      deliveryService: 'Delivery service',
      organizations: 'Companies',
      productType: 'Product type (CRM)',
      productTypeOffer: 'Product type (Offer)',
      gender: 'Gender',
      warehouse: 'Warehouse',
      age: 'Age',
      withErrors: 'Calculation errors',
    },
    modal: {
      attention: 'Warning',
      close: 'Close',
      total: 'Total',
      orders: 'orders',
      noData: 'Missing data for',
      ordersKC: 'orders for call center expenses',
      ordersLogistic: 'orders for logistics expenses',
      ordersTax: 'orders for fees and taxes',
      ordersLead: 'orders lead price',
      ordersPrime: 'orders for order cost',
    },
    infoTab: {
      information: 'Info',
      error: 'Error',
      dataIsAbsentFor:
        'Missing data for {amount} order for the viewed peroid. Click "Errors" to learn more.',
    },
    otherTotal: 'Total',
    withErrorsOnly: 'Only without errors',
    withoutErrorsOnly: 'Only with errors',
    amountOfErrorsTotal: 'Orders with errors: current month - {month}, year - {year}',
    errorNotFound: 'No errors found',
  },
  financialReportOrders: {
    title: 'Orders for financial report',
    table: {
      id: 'ID',
      country: 'Country',
      offer: 'Offer',
      landing: 'Landing',
      web: 'Web',
      income: 'Income',
      costPrice: 'Order cost',
      perLead: 'Per lead',
      delivery: 'Delivery',
      deliveryBack: 'Return delivery',
      onKC: 'Call center',
      tax: 'Fines and taxes',
      profit: 'Profit',
      otherErrors: 'Other errors',
    },
    info: 'Info',
    orders: 'Order',
    currency: 'Currency',
    id: 'Order No',
    createdAt: 'Created',
    country: 'Country',
    offer: 'Offer',
    salesType: 'Sales type',
    salesModel: 'Sales model',
    status: 'Status',
    source: 'Source',
    web: 'Web',
    productType: 'Product type',
    productTypeCrm: 'Product type',
    deliveryService: 'Delivery service',
    warehouse: 'Warehouse',
    ransom: 'Income',
    hasRansom: 'Delivered',
    ransomDate: 'Delivered date',
    ransomSum: 'Order value',
    ransomRate: 'Currency rate',
    ransomTotal: 'Income',
    lead: 'Affiliate payments expenses',
    hasLead: 'Hot',
    leadPrice: 'Affiliate payment',
    leadRate: 'Currency rate',
    leadTotal: 'Total',
    prime: 'Order cost expenses',
    isShipped: 'Shipped',
    noRansom: 'Canceled',
    canReturn: 'Returnable',
    disbanded: 'Disbanded',
    shippedDate: 'Shipped date/time',
    primeCost: 'Order cost',
    primeRate: 'Currency rate',
    primeTotal: 'Total',
    cc: 'Call center expenses',
    hasApprove: 'Approved',
    approveDate: 'Approved date/time',
    callCenterPrice: 'Call center',
    callCenterRate: 'Currency rate',
    callCenterTotal: 'Total',
    deliveryCoast: 'Delivery expenses',
    isShippedA: 'Shipped',
    shippedDateA: 'Delivery date/time',
    shippedPrice: 'Delivery',
    shippedRate: 'Currency rate',
    shippedTotal: 'Total',
    deliveryBack: 'Return delivery expenses',
    noRansomA: 'Canceled',
    canReturnA: 'No Return',
    disbandedA: 'Disbanded',
    noRansomDate: 'Canceled date/time',
    backPrice: 'Return delivery',
    backRate: 'Currency rate',
    backTotal: 'Total',
    ndc: 'Fees and taxes',
    tax: 'Tax/fee rate',
    check: 'Order value',
    checkRate: 'Currency rate',
    checkTotal: 'Order value',
    checkTax: 'Fees and taxes',
    profit: 'Profit',
    exportFile: 'Orders for financial report',
  },
  approveSpeed: {
    title: 'Time till approve',
    total: 'Total',
    totalTable: 'Total:',
    hours: 'Hour',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    offer: 'Offer',
    groupOffer: 'Offer groups',
    offer_group: 'Offer groups',
    offerNiches: 'Niche',
    offer_niche: 'Niche',
    countries: 'Country',
    region: 'State',
    operatorGroup: 'Operator group',
    user_group: 'Operator group',
    operator: 'Operator',
    landing: 'Landing',
    web: 'Web',
    deliveryService: 'Delivery service',
    delivery_service: 'Delivery service',
    salesModel: 'Sales model',
    sales_model: 'Sales model',
    salesType: 'Sales type',
    sales_type: 'Sales type',
    age: 'Age',
    gender: 'Gender',
    filterDateBy: {
      createdAt: 'By Created',
      approve: 'By Approved',
      processing: 'By Processing',
    },
    periods: {
      hour: 'Hours',
      day: 'Days',
      call: 'Calls',
    },
    filters: {
      offers: 'Offer',
      offerGroups: 'Offer groups',
      niches: 'Niches',
      webs: 'Webs',
      landings: 'Landings',
      salesModel: 'Sales models',
      salesTypes: 'Sales type',
      operators: 'Operators',
      userGroups: 'User groups',
      countries: 'Countries',
      regions: 'States',
      tags: 'Tags',
      deliveryServices: 'Delivery service',
    },
  },
  permission: {
    title: 'Permissions control',
    permissionTitle: 'Title',
    count: 'Users',
    viewPermission: 'View phone number',
    user: 'User',
    role: 'Role',
    group: 'Group',
    disableAccess: 'Disable permission',
    back: 'To list',
  },
  dostavim: {
    title: 'Postal/Courier delivery report',
    filters: {
      day: 'Day',
      month: 'Month',
      offer: 'Offer',
      offer_group: 'Offer groups',
      offer_niche: 'Niche',
      operator_groups: 'Operator group',
      operator: 'Operator',
      country: 'Country',
      region: 'State',
      sales_model: 'Sales model',
      sales_type: 'Sales type',
    },
    shipmentsCD: 'Available Delivery services',
    sentCD: 'Shipped by courier',
    mail: 'Postal delivery',
    deviationTarget: 'Target deviation',
    statistics: 'Statistics',
    setTarget: 'Set target',
    targetLabel: 'Target, %',
    madeByEmail: 'Postal delivery',
    target: 'Target',
  },
  ages: {
    less15: 'less than 15',
    more90: 'more than 90',
  },
  penalty: {
    title: 'Operator fines and bonuses',
    operator: 'Operator',
    operatorGroup: 'Operator group',
    penalty: 'Penalties',
    holds: 'Fines',
    addCharges: 'Additional bonuses',
    bulkActions: 'Bulk actions',
    action: 'Action',
    accrual: 'Additional bonuses',
    operators: 'Operators',
    date: 'Operation date',
    selectPenalty: 'Select penalty',
    sum: 'Amount',
    selectHold: 'Select fine',
    selectProfit: 'Select bonuses',
    success: 'Success',
    actionsSuccess: 'Applied to',
    actionsSuccessPart: 'operators',
    addPenalty: 'Add penalty',
    lastPenalty: 'Previous penalties',
    comment: 'Comment',
    datePenalty: 'Penalty date',
    addProfit: 'Add bonuses',
    lastProfit: 'Previous bonuses',
    dateProfit: 'Bonus date',
    profit: 'Bonus',
    addHold: 'Add fine',
    hold: 'Fine',
    dateHold: 'Fine date',
    lastHold: 'Previous fines',
    allModal: 'Operator fines and bonuses',
    penalties: 'Fines',
    userGroups: 'Operator groups',
  },
  createPassword: {
    title: 'Create password',
  },
  addSecurityPassword: {
    more15min: 'More than 15 minutes',
    more30min: 'More than 30 minutes',
    more1hour: 'More than 1 hour',
    more2hour: 'More than 2 hours',
    more3hour: 'More than 3 hours',
    more5hour: 'More than 5 hours',
    more24hour: 'More than 24 hours',
    noLogout: 'Do not logout',
    noLimits: 'No limits',
    showViewPhoneFull: 'Full',
    showViewPhonePartially: 'Partially',
    noShowViewPhone: 'Hide',
    error: 'Error',
  },
  metashipAccounts: {
    firstFormTitle: 'General',
    secondFormTitle: 'Shops',
    tooltipInfo: 'Look for your API token and secret key in your Deivery service account',
    dataUpdated: 'Synchronization successful',
    wrongParams: 'Wrong API token or secret key',
    table: {
      name: 'Title',
      description: 'Description',
      metashipId: 'Full Delivery aggregator ID',
      shortMetashipId: 'Short ID',
    },
    form: {
      name: 'Title',
      token: 'API token',
      description: 'Description',
      key: 'Secret key',
      synchronize: 'Synchronize',
    },
  },
  repeatedConfirm: {
    title: 'Reconfirmations',
    form: {
      ageFrom: 'Age from',
      ageTo: 'Age to',
      ageEmpty: 'No age',
      timeFrom: 'Approved after',
      timeTo: 'Approved before',
    },
  },
  deliveryServiceCode: {
    RussianPost: 'Post',
    TopDelivery: 'TopDelivery',
    Cdek: 'SuperDelivery',
    Boxberry: 'DHL',
    Drhl: 'DPD',
    Dpd: 'Omniva',
    Cse: 'Poczta Polska',
    FivePost: 'Nova Post',
    PickPoint: 'UkrPost',
    YandexGo: 'UPS',
    YandexDelivery: 'OneMoreDelivery',
    Svyaznoy: 'Fast Courier',
    DostavkaClub: 'InTime',
    DostavkaGuru: 'FlyParcel',
  },
  logWatchNumber: {
    title: 'Orders/phones views log',
    userGroups: 'User group',
    table: {
      date: 'Date',
      user: 'User',
      numberOrder: 'Order No',
      typeAction: 'Action type',
    },
    watch_number: 'Phone view',
    open_order: 'Order view',
    order_sms: 'Phone view',
    eventName: 'Action type',
    operator: 'User group',
    update: 'Update',
    create: 'Create',
  },
  logAccounts: {
    title: 'Accounts log',
    table: {
      date: 'Date',
      initiator: 'User',
      changeAccount: 'Updated account',
      typeAction: 'Action type',
    },
    event: {
      update: 'Edit account',
      create: 'Create account',
      user_update_access: 'Permissions update',
      user_update_password: 'Password reset',
      user_deactivation: 'Account deactivation',
      user_activation: 'Account activation',
    },
    numberPhone: 'Phone number',
    editAccess: 'Permissions update',
    eventName: 'Action type',
    users: 'Users',
    includesSensitiveAccess: 'Include sensitive permissions',
    dateAndTime: 'Date/time',
    account: 'Account',
    update: 'Edit',
    be: 'Before',
    became: 'After',
  },
  abNormal: {
    backToLogs: 'Back to log',
    btn: 'Suspicious',
    title: 'Suspicious views',
    table: {
      date: 'Date',
      user: 'User',
      userType: 'User type',
      typeAction: 'Action type',
      countView: 'Nubmer of views',
    },
    order: 'Order view',
    phone: 'Customer phone view',
    operator: 'Operator',
    header: 'Manager',
    userType: 'User type',
    typeAction: 'Action type',
    viewOrder: 'Orders views',
    modal: {
      numOrder: 'Order No',
      dateTime: 'Date/time',
      operator: 'First operator',
      country: 'Country',
      operatorFirstApprove: 'First approve operator',
    },
  },
  userSchedule: {
    title: 'Operator hours norms and schedules',
    titleCreate: 'Operator hours norms and schedules',
    norms: 'Hours norm',
    startShift: 'Shift starts',
    endShift: 'Shift ends',
    norma: 'Norms',
    intervals: 'Intervals',
    hours: 'hours',
    errorIntervals: 'Ranges must not match',
    errorNorms: 'This standard already exists.',
  },
  infoLogAccounts: {
    partially: 'Partially',
    phoneViewLimit: 'Max phone number views per day',
    phoneViewMode: 'View full phone number',
    isOperator: 'Operator',
    workStartDate: 'Employment date',
    email: 'Email',
    name: 'Name',
    lastName: 'Surname',
    bonusCurrency: 'Salary currency',
    grade: 'Operator grade',
    cardNumber: 'Bank account number',
    additionalCardNumber: 'Additional bank account number',
    telephonyUsername: 'Dialer Username',
    telephonyPassword: 'Dialer Password',
    groups: 'Group',
    permissions: 'Permissions',
    full: 'Full',
    countries: 'Country',
    languages: 'Language',
    offers: 'Offer',
  },
  operatorSchedule: {
    title: 'Operator work schedule',
    name: 'Name',
    group: 'Group',
    grade: 'Grade',
    normaHours: 'Hours commitment',
    plan: 'Planned',
    fact: 'Fact',
    schedule: 'Hours commitment',
    grades: 'Grades',
    markAsViewed: 'Check as viewed',
    history: 'History',
    date: 'Date/time',
    initiator: 'User',
    was: 'Before',
    became: 'After',
    noData: 'No data',
  },
  ticketTypes: {
    ticketTypes: 'Ticket types',
    ticketTypesCreate: 'Ticket type',
    ticketTypesEdit: 'Ticket type',
    main: 'General',
    form: {
      performerSettings: 'Assignee settings',
      deadlineSettings: 'Deadline settings',
      name: 'Title',
      deadlineFormat: 'Deadline format',
      autoPerformer: 'Autocomplete assignee',
      autoPerformerSign: 'By attribute',
      autoDeadline: 'Autocomplete deadline',
      autoDeadlineSign: 'By attribute',
      timeAfter: 'Time from order status assignment',
      timeAfterCreate: 'Time from ticket creation',
      timeAfterUnit: '',
      status: 'Status',
    },
    select: {
      exact: 'Date and time',
      timer: 'Countdown timer',
      status_time: 'By order status time',
      create_time: 'By ticket create time',
      first_approve: 'First approve',
      last_approve: 'Last approve',
      callback: 'Callback',
      shipped: 'Shipped',
      awaiting_ransom: 'Awaiting delivery',
      delivered: 'Delivered',
      no_ransom: 'Return',
      trash: 'Trash',
      canceled: 'Canceled',
    },
    autoPerformerSign: {
      first_approve: 'First approve operator',
    },
    list: {
      name: 'Title',
      deadlineFormat: 'Deadline format',
      autoDeadlineSign: 'Autocomplete deadline',
      autoPerformerSign: 'Autocomplete assignee',
    },
  },
  ccStatisticsDashboard: {
    ccStatisticsDashboard: 'Call center resources',
    amountOperatorsDays: 'Operators by hours',
    amountOperatorsWeeks: 'Operators by days of week',
    amountOperatorsMonths: 'Operators by days of month',
    planAmount: 'Planned operators',
    tableView: 'Table view',
    chartView: 'Graph.view',
    group: 'Group',
    plan: 'Planned',
    groupBy: {
      day: 'By hours',
      week: 'By days week',
      month: 'By days of month',
    },
  },
  smsSettings: {
    title: 'SMS settings',
    general: 'General',
    additional: 'Advanced',
    operatorSmsDefault: 'SMS provider by default',
    operatorSms: 'Promo SMS provider',
    beeline: 'Tele2',
    titleCountry: 'Country settings',
    titleAdditional: 'Advanced settings',
    operatorSent: 'SMS provider',
    operatorClient: 'Customer mobile network operator',
  },
  smsChain: {
    titleEdit: 'New notification chain',
    title: 'Notification chains',
    table: {
      id: 'ID',
      name: 'Title',
      county: 'Country',
      deliveryService: 'Delivery service',
      rateClient: 'Customer rating',
      date: 'Created',
      dateStart: 'Start date (approved after)',
      activeABTest: 'Active A/B testing',
      statusChain: 'Chain status',
      actions: 'Action',
    },
    tabs: {
      general: 'General',
      smsChain: 'Notification chain',
      stat: 'Statistics',
      statAB: 'A/B test stats',
    },
    titleGeneral: 'Filter orders',
    name: 'Title',
    rating: {
      good: 'Good',
      neutral: 'Neutral',
      bad: 'Bad',
      invalid: 'Invalid',
    },
    ratingTitle: 'Customer rating',
    status: 'Stop notifications when statuses',
    statuses: {
      draft: 'Draft',
      error: 'Error',
      pending: 'Pending verification',
      created: 'Created',
      waitDelivery: 'Preparing delivery',
      intransit: 'In transit',
      dispatchedToACourier: 'Dispatched to a courier',
      stored: 'Awaiting delivery',
      delivered: 'Delivered',
      expectedReturn: 'Preparing return',
      returnArrivedWarehouse: 'Returned to delivery service warehouse',
      returnCompleted: 'Returned to sender',
      losted: 'Lost',
      unknown: 'Status unknown',
      Canceled: 'Canceled',
    },
    service: 'Delivery service',
    country: 'Country',
    offers: 'Offers',
    dateApprove: 'Approved after',
    dateApproveTo: 'Approved before',
    filter: {
      rating: 'Client rating',
    },
    titleSmsChain: 'Base notification chain',
    titleStat: 'Statistics',
    titleStatAB: 'A/B testing stats',
    baseSmsChain: {
      firstTitle: 'SMS',
      firstSubtitle: 'Order arrived to delivery point',
      secondTitle: 'Robot call',
      secondSubtitle: 'Order arrived<br/> to delivery point, <br/>pick it up today',
      threeTitle: 'SMS',
      threeSubtitle: 'Order arrived<br/> to delivery point, <br/>pick it up today',
      addChain: 'Add<br/> notification',
    },
    unit: 'Notification',
    nameUnit: 'Title',
    type: {
      sms: 'SMS',
      operatorCall: 'Operator call',
    },
    typeUnit: 'Notification type',
    operatorUnit: 'SMS service',
    statusUnit: 'Delivery service status',
    templateUnit: 'Template',
    timeAfterUnit: '',
    timeAfter: 'Time from receiving DS status',
    timeAfterObj: {
      day: 'days',
      hour: 'hours',
      minute: 'minutes',
    },
    costUnit: 'One notification expenses',
    textSms: 'SMS content',
  },
  twoFactorAuthSelect: {
    title: 'Two-factor authentication',
    enabled: 'Enabled',
    disabled: 'Disabled',
  },
  autoCall: {
    title: 'Autodialing',
    id: 'Order ID',
    date: 'Created',
    web: 'Web',
    status: 'Order status',
    country: 'Country',
    dateAddedQueue: 'Added to queue',
    dateDeletedQueue: 'Removed from queue',
    dateLastCall: 'Last call date',
    statusLastCall: 'Last call status',
    statusOktell: 'Dialer status',
    countCalls: 'Number of calls',
    applicationQueue: 'Order in queue',
    filter: {
      country: 'Country',
      statusCall: 'Call status',
      statusOrder: 'Order status',
      offer: 'Offer',
      niche: 'Niche',
      offerGroup: 'Offer group',
      operatorGroup: 'Operator group',
      web: 'Web',
    },
    restart: 'Restart',
    removeQueue: 'Remove from queue',
  },
  sendSms: {
    title: 'Send SMS',
    textSms: 'SMS content',
    template: 'Template',
    successMessage: 'Message sent',
    noTemplate: 'No template',
  },
  trackingModal: {
    title: 'Order tracking No',
    noInfo: 'No tracking data.',
    status: {
      draft: 'draft',
    },
  },
  orderReportFileName: {
    shippedMoreThan20Days: 'Sent more than 20 days (1025_1)',
    shippedMoreThanDaysNoBarCode: 'Sent more than 15 days without barcode (1025_2)',
    approvedMoreThan14Day: 'Approval more than 14 days for Courier (1025_3)',
    approvedMoreThan10Day: 'Approval more than 10 days for NOT Courier (1025_3.2)',
    awaitingRansomMore30Day: 'Waiting for redemption for more than 30 days Mail (1025_4)',
    awaitingRansomMore15Day: 'Waiting for redemption for more than 15 days except Mail (1025_4.2)',
    summaryPointKln: 'Total CLN score for',
    deliveryInterval: 'Delivery intervals',
    orders: 'Orders',
    changesPoint: 'Delivery point changes',
    lastStatus: 'Last status',
  },
  dashboardMixin: {
    exportToSVG: 'Save SVG',
    exportToPNG: 'Save PNG',
    exportToCSV: 'Save CSV',
    menu: 'Menu',
    selection: 'Selection',
    selectionZoom: 'Selection zoom',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    pan: 'pan',
    reset: 'reset',
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    shortMonths: {
      january: 'Jan',
      february: 'Feb',
      march: 'Mar',
      april: 'Apr',
      may: 'May',
      june: 'Jun',
      july: 'Jul',
      august: 'Aug',
      september: 'Sep',
      october: 'Oct',
      november: 'Nov',
      december: 'Dec',
    },
    days: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    shortDays: {
      sunday: 'Sun',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
    },
  },
  salesStatTranslation: {
    cancelled: 'Cancelled',
    no_ransom: 'Return',
    hot: 'Hot',
    repeated: 'Repeated',
    certificate: 'Certificate',
    repeated_confirm: 'Repeated confirm',
  },
}
export default locale
